.svg-icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
  stroke: currentColor;
  padding: 2px;
}

.svg-icon--stroke {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}
