$font-size-menu-height: 2.5rem;

.font-size-scale--large {
  font-size: 80%;
}

.font-size-scale--small {
  font-size: 50%;
}

.font-size-menu {
  display: flex;
  justify-content: center;
}

.font-size-menu__button {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: none;
  border: none;

  display: flex;
  justify-content: center;
  height: calc(#{$font-size-menu-height} + #{$unit-s  * 2});
  color: $color-brand-dark;
  @include font-smoothing(false);
  padding: $unit-s;
  cursor: pointer;
  line-height: $font-size-menu-height;

  &:hover,
  &:focus {
    background-color: rgba($color-brand-dark, 0.1);
  }

  @media all and (max-width: $desktop-app-menu-breakpoint - 1px) {
    @include darkTheme() {
      color: $color-light;
      @include font-smoothing(true);
    }
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    color: $color-light;
    @include font-smoothing(true);
  }

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }
}

.font-size-scale--large .font-size-menu__button--large,
.font-size-scale--small .font-size-menu__button--small {
  background-color: rgba($color-brand-dark, 0.1);
}

.font-size-menu__icon {
  display: block;
  margin-left: $unit-xs;

  @include bold('font-accent');
  line-height: $font-size-menu-height;
}

.font-size-menu__icon--small {
  font-size: 1.1em;
}

.font-size-menu__icon--large {
  font-size: 1.7em;
}
