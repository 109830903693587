$list-bullet-size: 6px;

.post-list {
  &:not(:first-child) {
    margin-top: $unit-l;
  }
}

.post-list--ordered {
  counter-reset: list-counter;
}


.post-list__item {
  position: relative;
  margin-right: $unit-l;
  padding-right: $unit-l;

  .post-list__item + & {
    margin-top: $unit-s;
  }

  @media screen and (min-width: $breakpoint-600) {
    margin-right: $unit-xl;
  }

  @media screen and (min-width: $breakpoint-800) {
    margin-right: $unit-xxl - $unit-m;
  }
}

.post-list--ordered .post-list__item {
  counter-increment: list-counter;
}

.post-list__item:before {
  position: absolute;
  right: 0;
}

.post-list--unordered .post-list__item:before {
  content: '';
  top: calc(0.9em - #{$list-bullet-size * .5});
  width: $list-bullet-size;
  height: $list-bullet-size;

  border-radius: 50%;
  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-light;
  }
}

.post-list--ordered .post-list__item:before {
  content: counter(list-counter, arabic-indic) '\002e';
  top: 0;
  right: 0;
  color: $color-brand-dark;

  @include darkTheme() {
    color: $color-brand-light;
  }
}
