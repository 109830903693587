@import "app-header.variables";

.app-header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 50;
}

.app-header__logo {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  width: $logo-header-width-mobile;
  height: $logo-header-height-mobile;
  transform: translateX(-50%);

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    width: $logo-header-width-desktop;
    height: $logo-header-height-desktop;
  }

  @media screen and (min-width: $breakpoint-1200) {
    width: $logo-header-width-desktop;
    height: 52px; // custom number based on aspect ratio svg
  }
}

.app-header__logo .svg-icon {
  position: relative;
  display: block;
  transform: scale(0.8);

  fill: $color-light;
  color: $color-light;
  stroke-width: 0;

  @media screen and (min-width: $breakpoint-1200) {
    top: 20px;
    transform: scale(1);
  }

  &.logo--small {
    @media screen and (min-width: $breakpoint-1200) {
      display: none;
    }
  }

  &.logo--large {
    display: none;

    @media screen and (min-width: $breakpoint-1200) {
      display: block;
    }
  }
}

.app-header__nav {
  position: relative;
  order: 4;
  width: 100%;
  z-index: 10;

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    display: flex;
  }
}
