.promoted-slot {
  max-width: 100%;
  position: relative;
  clear: both;

  @media screen and (max-width: $breakpoint-400) {
    overflow: hidden;
  }

  @media (prefers-reduced-motion: reduce) {
    display: none;
  }
}

.promoted-slot__container {
  position: relative;
  height: 280px;

  @media screen and (min-width: 985px) {
    height: 90px;
  }
}

.promoted-slot__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
