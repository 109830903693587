.category__posts-primary {
  position: relative;
  bottom: -$unit-m; // overcome margin-bottom of card-primary
  margin-top: $unit-m;

  @media all and (min-width: $breakpoint-800) {
    margin-top: $unit-xl;
  }
}

.category__top {
  position: relative;
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.category__posts-secondary {
  margin-top: $unit-xl;
}

.category__content {
  position: relative;
}

.category__advertisment {
  min-height: 200px;
  padding: $unit-l;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $color-grey-medium;

  & + .category__advertisment {
    margin-top: $unit-m;
  }
}

.category__pagination {
  display: flex;
  gap: 20px;
  justify-content: center;
}
