.section-primary {
  position: relative;
  margin-top: $unit-l;

  @media screen and (min-width: $breakpoint-1000) {
    margin-top: $unit-xl;
  }
}


.section-primary__content {
  position: relative;
  z-index: 1;
  padding-top: $unit-l;
  padding-bottom: $unit-l;

  @media screen and (min-width: $breakpoint-1200) {
    display: flex;
    align-items: flex-start;
    padding-top: $unit-xl;
    padding-bottom: $unit-xl;
  }
}

.section-primary__featured {
  @media screen and (min-width: $breakpoint-600) {
    width: calc(100 / 4 * 3 * 1%);
  }

  @media screen and (min-width: $breakpoint-1200) {
    width: calc(100 / 6 * 2 * 1%);
  }
}

.section-primary__grid {
  margin-top: $unit-m;

  @media screen and (min-width: $breakpoint-800) {
    display: flex;
    flex-wrap: wrap;
    margin-top: $unit-l;
  }

  @media screen and (min-width: $breakpoint-1200) {
    width: calc(100 / 6 * 4 * 1%);
    margin-top: 0;
  }
}

.section-primary__grid > * {
  width: 100%;

  @media screen and (max-width: $breakpoint-800 - 1) {
    &:nth-child(n+2) {
      margin-top: $unit-m;
    }
  }

  @media screen and (min-width: $breakpoint-800) {
    width: 50%;

    &:nth-child(n+3) {
      margin-top: $unit-l;
    }

    > * {
      min-height: 100%;
    }
  }

}

.section-primary .grid-lines {
  z-index: 2;
}
