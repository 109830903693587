.reading-time {
  color: inherit;
  @include bold('font-accent');
  font-size: 1.5rem;
  line-height: 1.2em;
  @include font-smoothing();
}

.reading-time__icon {
  display: inline-block;
  vertical-align: bottom;
  width: $icon-size-small;
  height: $icon-size-small;
  min-width: $icon-size-small;
  min-height: $icon-size-small;
}
