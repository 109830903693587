.skip-link {
  background-color: $color-light;
  color: $color-brand-dark;
  position: absolute;
  right: -9999rem;
  top: $unit-s;
  z-index: 51;
}

.skip-link:focus {
  right: $unit-s;
}
