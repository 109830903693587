$settings-duration: .15s;
$settings-delay: .05s;
$settings-icon-size: 18px;

.settings-menu {

  @media all and (max-width: $desktop-app-menu-breakpoint - 1px) {
    position: relative;
    background-color: $color-brand-dark-shade-2;

    @include darkTheme() {
      background-color: $color-brand-light-shade-1--darken;
    }
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $desktop-navigation-height;
  }
}

.settings-menu__toggle {
  z-index: 2;
  position: relative;
  display: block;
  width: $quick-nav-height;
  height: $quick-nav-height;
}

.settings-menu__toggle-open {
  #settings-menu-mobile:target &,
  #settings-menu-desktop:target &,
  .settings-menu.toggle--active & {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.settings-menu__toggle-close {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  #settings-menu-mobile:target &,
  #settings-menu-desktop:target &,
  .settings-menu.toggle--active & {
    position: relative;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
}

.settings-menu__toggle .svg-icon {
  display: block;
  width: $quick-nav-height;
  height: $quick-nav-height;

  fill: $color-brand-dark;
  transform: scale(.6) rotate(0);
  transition: transform $settings-duration;

  @include darkTheme() {
    fill: $color-light;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    fill: $color-light;
  }

  #settings-menu-mobile:target &,
  #settings-menu-desktop:target &,
  .settings-menu.toggle--active &  {
    transform: scale(.6) rotate(90deg);
  }
}

.settings-menu__overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  display: none;

  #settings-menu-mobile:target &,
  #settings-menu-desktop:target &,
  .settings-menu.toggle--active &  {
    display: block;
  }
}

.settings-menu__content {
  z-index: 2;
  position: absolute;
  padding-bottom: $unit-s;

  pointer-events: none;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition:
    transform $settings-duration $settings-delay $ease,
    visibility 0s $settings-duration;

  #settings-menu-mobile:target &,
  #settings-menu-desktop:target &,
  .settings-menu.toggle--active &  {
    pointer-events: auto;
    visibility: visible;
    transform: scaleY(1);
    transition: transform $settings-duration $settings-delay $ease;
  }

  @media all and (max-width: $desktop-app-menu-breakpoint - 1px) {
    bottom: 100%;
    left: -$quick-nav-height;
    width: 200px;
    background-color: $color-brand-dark-shade-2;

    @include darkTheme() {
      background-color: $color-brand-light-shade-1--darken;
    }
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    right: 0;
    left: 0;
    top: 100%;

    background-color: $color-brand-dark;
    transform-origin: 50% 0%;

    @include darkTheme() {
      background-color: $color-brand-dark--darken;
    }
  }
}

.settings-menu__content > * {
  opacity: 0;
  transform: translateY(10px);
  transition:
    transform $settings-duration,
    opacity $settings-duration - $settings-delay linear;

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    transform: translateY(-10px);
  }
}

.settings-menu__divider {
  position: relative;
  margin-top: $unit-xs;
}

.settings-menu__divider:before {
  content: '';
  position: absolute;
  top: 0;
  left: $unit-m;
  width: calc(100% - #{$unit-m * 2});
  border-top: 1px solid $color-border;

  @media all and (max-width: $desktop-app-menu-breakpoint - 1px) {
    @include darkTheme() {
      border-top: 1px solid $color-border-light;
    }
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    border-top: 1px solid $color-border-light;
  }
}

#settings-menu-mobile:target,
#settings-menu-desktop:target,
.settings-menu.toggle--active {
  .settings-menu__content > * {
    opacity: 1;
    transform: translateY(0);
    transition-delay: $settings-duration;
  }
}


.settings-menu__button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  text-align: right;
  cursor: pointer;

  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: $unit-s;

  li:first-child > & {
    padding-top: 15px;
  }

  color: $color-brand-dark;
  font-size: inherit;
  line-height: 1.2;
  transition: background-color .1s linear;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  &:hover,
  &:focus {
    background-color: rgba($color-black, 0.1);
    outline: none;

    @include darkTheme() {
      background-color: rgba($color-black, 0.2);
      outline: none;
    }
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    color: $color-light;
    @include font-smoothing(true);

    &:hover,
    &:focus {
      background-color: rgba($color-black, 0.4); // $color-brand-dark-shade-2--darken;
    }
  }
}

.settings-menu__button {
  .svg-icon {
    display: block;
    width: $settings-icon-size;
    height: $settings-icon-size;
    margin-top: .1em;
    padding: 0;
    margin-left: $unit-s;
  }
}

.settings-menu .bookmark-count {
  position: unset;
  margin-right: $unit-s;

  @include darkTheme() {
    background: $color-light;
    color: $color-brand-light-shade-1--darken;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    background: $color-light;
    color: $color-brand-dark;

    @include darkTheme() {
      background: $color-light;
      color: $color-brand-dark--darken;
    }
  }
}
