$slider-offset: 100px;
$slider-image-height-small: 250px;
$slider-image-height-large: 400px;

.slider {
  position: relative;
  margin-bottom: $unit-m;
  overflow: hidden;
  background-color: $color-brand-dark-shade-2;

  clear: both;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-2--darken;
  }

  .enhanced & {
    cursor: move;
    cursor: grab;
  }

  .enhanced &:active {
    cursor: grabbing;
  }

  &:focus-within {
    outline: thick auto;
    outline-offset: -1px;
  }
}

.slider__hint {
  position: absolute;
  top: 0;
  left: 0;
  width: calc((100% - #{$max-width-s}) * 0.5);
  min-width: 100px;
  max-width: 400px;
  height: 100%;
  background-image: linear-gradient(to right, $color-brand-dark-shade-2 20px, rgba($color-brand-dark-shade-2, 0));
  background-size: cover;
  pointer-events: none;

  @include darkTheme() {
    background-image: linear-gradient(to right, $color-brand-dark-shade-2--darken, rgba($color-brand-dark-shade-2--darken, 0));
  }
}

.slider__hint span {
  position: absolute;
  top: 50%;
  left: 2em;
  transform: translateX(-50%) rotate(-90deg);
  line-height: 1.4em;
  color: $color-dark;
  white-space: nowrap;

  @include darkTheme() {
    color: $color-light;
  }
}

.slider__hint--grab {
  position: relative;
  display: none;
}

.slider__hint--grab .svg-icon {
  position: absolute;
  left: calc(100% + #{$unit-s});
  top: calc(50% - #{$icon-size * .5});
  width: $icon-size;
  height: $icon-size;
  transform: rotate(90deg);
}

.enhanced {
  .slider__hint--scroll {
    display: none;
  }

  .slider__hint--grab {
    display: block;
  }
}

.slider__slides {
  width: 100%;
  margin-bottom: -$slider-offset;
  padding-bottom: $slider-offset;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.slider__slides:before,
.slider__slides:after {
  content: '';
  display: block;
  width: calc(50vw - #{$max-width-s * 0.5} + #{$unit-m});
  flex-shrink: 0;
}

.slider__slide {
  flex-shrink: 0;
  height: 100%;

  &:not(:last-child) {
    margin-left: $unit-m;

    @media screen and (min-width: $breakpoint-800) {
      margin-left: $unit-l;
    }
  }
}

.slider__slide img {
  display: block;
  height: $slider-image-height-small;
  width: auto;

  @media screen and (min-width: $breakpoint-800) {
    height: $slider-image-height-large;
  }
}

.slider__caption {
  padding-top: $unit-s;
  padding-right: $unit-s;
  padding-left: $unit-s;
}
