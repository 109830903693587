$color-dark: #242424;
$color-black: #121212;
$color-dark-muted: #919191;
$color-dark-muted--darken: #333333;
$color-light: white;

$color-grey-light: #E0E0E0;
$color-grey-medium: #dedede;

$color-brand-light: #b2a393;
$color-brand-light-shade-1: #e5dcd4;
$color-brand-light-shade-2: #F2EDE9;
$color-brand-light--darken: #7F756B;
$color-brand-light-shade-1--darken: #393736;
$color-brand-light-shade-2--darken: #282726;

$color-brand-dark: #24257a;
$color-brand-dark-shade-1: #9192bc;
$color-brand-dark-shade-2: #dedfeb;
$color-brand-dark--darken: #484d9b;
$color-brand-dark-shade-1--darken: #373C77;
$color-brand-dark-shade-2--darken: #202544;

$color-yellow: #F5B82F;
$color-yellow-shade-1: #F9DB96;
$color-yellow-shade-2: #FDF1D5;
$color-yellow--darken: #E0A735;
$color-yellow-shade-1--darken: #A57A2D;
$color-yellow-shade-2--darken: #443215;

$color-green: #23A491;
$color-green-shade-1: #94e3d7;
$color-green-shade-2: #d5f4ef;
$color-green--darken: #22907d;
$color-green-shade-1--darken: #1D7060;
$color-green-shade-2--darken: #133F37;

$color-red: #DE2C0E;
$color-red-shade-1: #f3a598;
$color-red-shade-2: #fbdbd6;
$color-red--darken: #CE4836;
$color-red-shade-1--darken: #822F2B;
$color-red-shade-2--darken: #5B1F21;

$color-blue: #5674B2;
$color-blue-shade-1: #b6caf4;
$color-blue-shade-2: #e2eafb;
$color-blue--darken: #517BB7;
$color-blue-shade-1--darken: #365172;
$color-blue-shade-2--darken: #253749;

$color-warn: $color-red;

$color-mirror-up: $color-green;
$color-mirror-low: $color-yellow;
$color-mirror-down: $color-red;

$color-border: rgba($color-brand-dark, .1);
$color-border-light: rgba($color-light, .1);
