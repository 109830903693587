.external-banners--primary {
  background-color: $color-brand-dark-shade-1;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }
}

.external-banners--secondary {
  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }
}

.external-banners__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.external-banners__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: $unit-m;

  @media all and (min-width: $breakpoint-600) {
    min-width: 330px;
    max-width: 400px;
    padding-right: $unit-m;
  }

  @media all and (min-width: $breakpoint-1200) {
    max-width: 500px;
  }
}

.external-banners__link {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 2;
  padding: $unit-m;

  background-color: $color-brand-dark-shade-2;
  box-shadow: 0 10px 8px rgba(black, .02);

  transition: transform .1s, box-shadow .1s linear;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-2--darken;
    box-shadow: 0 10px 15px rgba(black, .1);
  }

  &:hover,
  &:focus {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(black, .07);
  }
}

.external-banners__link:before {
  content: '';
  position: absolute;
  top: $unit-m;
  right: 50%;
  height: calc(100% - #{$unit-m * 2});
  border-right: 1px solid $color-border;
}

.external-banners__image-container {
  display: block;
  width: 50%;
  padding-left: $unit-m;
}

.external-banners__image {
  display: block;
  width: 100%;
  height: 70px;
  object-fit: contain;
}

.external-banners__link-title {
  width: 50%;
  padding-right: $unit-m;
}

.external-banners__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: $icon-size-medium;
  height: $icon-size-medium;

  color: $color-brand-dark;
  background-color: $color-brand-dark-shade-2;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-2--darken;
    color: $color-light;
  }

  display: none;

  .external-banners__link[href*="//"]:not([href*="radiozamaneh.com"]) &,
  .external-banners__link[href*="//"]:not([href*="www.radiozamaneh.com"]) & {
    display: block;
  }
}

.external-banners__icon .svg-icon {
  transform: translateX(-25%) translateY(-25%) rotate(-135deg);
}
