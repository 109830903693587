.offline-message {
  display: none;
}

.offline-message--is-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $unit-s;

  background-color: $color-warn;
}

.offline-message p {
  text-align: center;
}
