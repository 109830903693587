.quick-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $quick-nav-height;
  z-index: 50;

  display: flex;
  align-items: center;
  padding-top: $unit-s;
  padding-right: $quick-nav-height;
  padding-bottom: $unit-s;

  background-color: $color-light;
  box-shadow: 0 0 20px rgba(black, 0.1);

  @include darkTheme() {
    background-color: $color-dark;
    box-shadow: 0 0 20px rgba(black, 0.3);
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    display: none;
  }
}

.quick-navigation__link.button {
  flex-grow: 2;
  padding-right: 5px;
  padding-left: 5px;
}

.quick-navigation__link-text {
  @media screen and (max-width: 749px) {
    @include visually-hidden;
  }
}

.quick-navigation__link.button--text-icon .svg-icon {
  margin-right: 0;
}

.quick-navigation__bookmark-icon {
  position: relative;
}
