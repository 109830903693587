$theme-graphic-width: 40px;
$theme-graphic-height: 24px;
$theme-point-offset: 6px;

.theme-menu {
  position: relative;
  display: flex;
  width: 100%;
  padding: $unit-s;
}

.theme-menu__toggle {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;

  position: relative;
  display: block;
  width: 50%;
  height: $square-button-size;

  cursor: pointer;
  color: $color-brand-dark;


  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    color: $color-light;
    @include font-smoothing(true);
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.theme-menu__toggle:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + #{$theme-graphic-width * .5});
  height: 100%;
  opacity: .5;
}

.theme-menu__toggle-dark {
  padding-left: $theme-graphic-width * .5 + $unit-s;
  text-align: left;

  &:before {
    right: 0;
  }

  html.color-theme-dark & {
    pointer-events: none;
    opacity: .7;
  }
}

.theme-menu__toggle-light {
  padding-right: $theme-graphic-width * .5 + $unit-s;
  text-align: right;

  &:before {
    left: 0;
  }

  html.color-theme-light & {
    pointer-events: none;
    opacity: .6;
  }
}

.theme-menu__graphic {
  position: absolute;
  top: calc(50% - #{$theme-graphic-height * .5});
  left: calc(50% - #{$theme-graphic-width * .5});
  width: $theme-graphic-width;
  height: $theme-graphic-height;

  border-radius: 20px;
  box-shadow: inset 0 0 0 2px $color-brand-dark;

  pointer-events: none;
  transition: transform .15s;

  @include darkTheme() {
    box-shadow: inset 0 0 0 2px $color-light;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    box-shadow: inset 0 0 0 2px $color-light;
  }

  .theme-menu__toggle:hover ~ &,
  .theme-menu__toggle:focus ~ & {
    transform: scale(1.08);
  }
}

.theme-menu__graphic:before {
  content: '';
  position: absolute;
  top: $theme-point-offset;
  left: $theme-point-offset;
  width: $theme-graphic-height - $theme-point-offset * 2;
  height: $theme-graphic-height - $theme-point-offset * 2;

  border-radius: 50%;
  background-color: $color-brand-dark;

  transition: transform .2s ease-out;

  @include darkTheme() {
    background-color: $color-light;
    transform: translateX($theme-graphic-width - $theme-graphic-height);
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    background-color: $color-light;
  }
}

