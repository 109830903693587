$progress-height: 25px;

.daadkhast-banners {
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.daadkhast-banners__logo {
  display: inline-block;
  width: 200px;
  height: 60px;
}

.daadkhast-banners__items {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

.daadkhast-banners__item {
  width: 100%;
  max-width: 400px;
  padding-top: $unit-m;
  padding-left: $unit-s;
  padding-right: $unit-s;
}

.daadkhast-banners__item-inner {
  display: flex;
  flex-direction: column;

  background-color: $color-brand-light-shade-2;
  box-shadow: 0 0 20px rgba(black, .1);

  @include darkTheme() {
    background-color: $color-brand-light-shade-2--darken;
    box-shadow: 0 0 20px rgba(black, .2);
  }

  > * {
    width: 100%;
  }
}

.daadkhast-banners__title {
  @include bold('font-base');
  color: $color-brand-dark;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }
}


.daadkhast-banners__image {
  order: 1;
  padding-top: $card-img-ratio;
  background-color: $color-brand-light;

  @include darkTheme() {
    background-color: $color-brand-light--darken;
  }

  img {
    object-fit: cover;
    object-position: center;
  }
}

.daadkhast-banners__progress-container {
  position: relative;
  order: 2;
}

.daadkhast-banners__progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;

  position: relative;
  display: block;
  width: 100%;
  height: $progress-height;

  // firefox background
  background-color: $color-brand-dark-shade-1;
  // IE11 background
  color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-2--darken;
    color: $color-brand-dark-shade-2--darken;
  }

  &::-webkit-progress-bar {
    background-color: $color-brand-dark-shade-1;

    @include darkTheme() {
      background-color: $color-brand-dark-shade-2--darken;
    }
  }

  &::-webkit-progress-value {
    background-color: $color-brand-dark;

    @include darkTheme() {
      background-color: $color-brand-dark--darken;
    }
  }

  &::-moz-progress-bar {
    background-color: $color-brand-dark;

    @include darkTheme() {
      background-color: $color-brand-dark-shade-1--darken;
    }
  }
}

.daadkhast-banners__progress-value {
  position: absolute;
  top: 0;
  padding-top: 2px;
  padding-right: $unit-s;
  padding-left: $unit-s;

  color: $color-light;
  font-size: 1.6rem;
  @include bold('font-accent');
  @include font-smoothing(true);
  line-height: $progress-height;

  .daadkhast-banners__progress--right & {
    transform: translateX(100%);
  }
}

.daadkhast-banners__content {
  order: 3;
  padding: $unit-m;
}

.daadkhast-banners__actions:not(:only-child) {
  margin-top: $unit-m;
}

.daadkhast-banners__button .svg-icon {
  transform: translateX(-20%) translateY(-10%);
}
