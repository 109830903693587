.bookmarks__no-bookmarks {
  display: none;

  .bookmarks__list:empty ~ & {
    display: block;
  }
}

.bookmarks__list:empty {
  display: none;
}

.bookmarks__no-support {
  display: none;

  &.is-visible {
    display: block;
  }
}
