.embed-infogram__graph {
  position: relative;
  height: 0;
  padding-bottom: 94%;

  @media screen and (min-width: 520px) {
    padding-bottom: 86%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
