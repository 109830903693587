// to store units used for margin, padding, spacings, max-width, etc
$base-unit: 10px;

$unit-xs: $base-unit * 0.5;
$unit-s: $base-unit;
$unit-m: $base-unit * 2;
$unit-l: $base-unit * 3;
$unit-xl: $base-unit * 5;
$unit-xxl: $base-unit * 10;

$max-width-xs: 400px;
$max-width-s: 780px;
$max-width-m: 1000px;
$max-width-l: 1200px;
$max-width-xl: 1400px;
