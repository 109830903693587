.post-interview__answer {
  padding-right: $unit-m;

  &:not(:first-child) {
    margin-top: $unit-s;
  }

  @media screen and (min-width: $breakpoint-600) {
    padding-right: $unit-xl;
  }
}
