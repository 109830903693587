$post-series-small:  250px;
$post-series-medium:  350px;
$post-series-large:  400px;
$post-series-offset: 100px;

.post-series {
  position: relative;
  padding-top: $unit-m;

  clear: both;
}

.post-series:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100px;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(to right, $color-light, rgba($color-light, 0));
  background-size: cover;

  @include darkTheme() {
    background-image: linear-gradient(to right, $color-dark, rgba($color-dark, 0));
  }
}

.post-series__slider {
  overflow: hidden;

  &:not(:first-child) {
    margin-top: $unit-s;
  }
}

.post-series__inner {
  display: flex;
  max-width: 100%;
  margin-bottom: -$post-series-offset;
  padding-bottom: $post-series-offset;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.post-series__inner:before,
.post-series__inner:after {
  content: '';
  display: block;
  width: calc(50vw - #{$max-width-s * 0.5} + #{$unit-m});
  flex-shrink: 0;
}

.post-series__list {
  position: relative;
  z-index: 1;
  display: flex;
}

.post-series__item {
  width: $post-series-small;
  min-width: $post-series-small;

  @media all and (min-width: $breakpoint-400) {
    width: $post-series-medium;
    min-width: $post-series-medium;
  }

  @media all and (min-width: $breakpoint-1200) {
    width: $post-series-large;
    min-width: $post-series-large;
  }
}
