$quick-nav-height: 50px;
$desktop-app-menu-breakpoint: 960px;

$header-banner-height-mobile: 80px;
$header-banner-height-desktop: 100px;

$logo-header-width-mobile: 90px;
$logo-header-height-mobile: $header-banner-height-mobile;

$logo-header-width-desktop: 130px;
$logo-header-height-desktop: $header-banner-height-desktop;
$logo-header-width-desktop: 250px;
