$news-feed-offset: 100px;
$news-feed-item-width: 120px;
$news-feed-item-height: 60px;

.news-feed__nav {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  height: $news-feed-item-height;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
    border-top: 1px solid $color-border-light;
    border-bottom: 1px solid $color-border-light;
  }

  @include darkTheme() {
    color: $color-brand-light;
    @include font-smoothing(true);
  }
}

.news-feed__nav:before,
.news-feed__nav:after {
  content: '';
  position: absolute;
  top: 0;
  z-index: 2;
  width: 50px;
  height: 100%;
  pointer-events: none;
  background-size: cover;
}

.news-feed__nav:before {
  left: 0;
  background-image: linear-gradient(to right, $color-light, rgba($color-light, 0));

  @include darkTheme() {
    background-image: linear-gradient(to right, $color-dark, rgba($color-dark, 0));
  }
}

.news-feed__nav:after {
  right: 0;
  background-image: linear-gradient(to left, $color-light, rgba($color-light, 0));

  @include darkTheme() {
    background-image: linear-gradient(to left, $color-dark, rgba($color-dark, 0));
  }
}

.news-feed__nav-container {
  width: 100%;
  margin-bottom: -$news-feed-offset;
  padding-bottom: $news-feed-offset;
  overflow-x: auto;
}

.news-feed__nav-items {
  position: relative;
  display: flex;
  z-index: 1;
}

.news-feed__nav-item {
  // a width must be set
  width: $news-feed-item-width;
  min-width: $news-feed-item-width;
  line-height: 1.2em;
}

.news-feed__nav-item > * {
  height: $news-feed-item-height;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: $unit-s;
  padding-bottom: $unit-s;
}

.news-feed__nav-item a {
  color: inherit;

  &:hover,
  &:focus {
    background-color: $color-border;
    color: $color-dark;

    @include darkTheme() {
      background-color: $color-border-light;
      color: $color-light;
    }
  }
}


.news-feed__item {
  padding-top: $unit-xl + $unit-l;

  &:target {
    animation: target-highlight 3s linear forwards
  }

  @include darkTheme() {
    animation-name: target-highlight-dark;
  }
}

@keyframes target-highlight {
  from {
    background-color: $color-yellow-shade-2;
  }
  to {
    background-color: rgba($color-yellow-shade-2, 0);
  }
}

@keyframes target-highlight-dark {
  from {
    background-color: $color-brand-light-shade-1--darken;
  }
  to {
    background-color: rgba($color-brand-light-shade-1--darken, 0);
  }
}

.news-feed__item-header {
  position: relative;
  display: flex;
  flex-direction: column;

  > * {
    order: 2;
  }
}

.news-feed__item-date {
  order: 1;

  > * {
    display: inline-block;
    padding-right: $unit-xs;
  }

  @media screen and (min-width: $breakpoint-1000) {
    position: absolute;
    top: $unit-s;
    left: 100%;
    width: 100px;

    > * {
      display: block;
      padding-right: 0;
      line-height: 1.4em;
      text-align: left;
    }
  }
}

.news-feed__item-share {
  margin-top: $unit-xs;
}
