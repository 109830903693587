.post-quote--default {
  padding-top: $unit-s;
}

.post-quote__inner {
  padding-right: $unit-l;
  border-right: 4px solid $color-brand-dark;

  .post-quote--default & {
    border-right: 0;

    @include darkTheme() {
      border-right: 0;
    }
  }

  @include darkTheme() {
    border-right: 4px solid $color-brand-light;
  }

  @media screen and (min-width: $breakpoint-800) {
    padding-right: $unit-xl;
  }
}

.post-quote__quotation {
  quotes: '\00AB' '\00BB';

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  .post-quote--default & {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      @include fontAccent();
      font-size: 3em;
      quotes: none;
    }

    &:before {
      content: '\201C';
      top: -.15em;
      right: -.15em;
    }

    &:after {
      content: '\201D';
      top: .05em;
      right: -.45em;
    }
  }
}
