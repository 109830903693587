.opinion-panel {
  display: grid;
  gap: $unit-xl;

  @media screen and (max-width: $breakpoint-600 - 1px) {
    padding-top: $unit-m;
  }

  @media screen and (min-width: $breakpoint-800) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    gap: 0;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.opinion-panel__header {
  @media screen and (min-width: $breakpoint-800) {
    padding-left: $unit-m;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    padding-left: $unit-l;
  }
}

.opinion-panel__logo {
  display: block;
  width: 100%;
  max-width: 300px;
}

.opinion-panel__actions {
  margin-top: $unit-l;
}
