$featured-image-icon: 26px;
$featured-image-caption-background: rgba(black, 0.5);

.featured-image {
  position: relative;
  overflow: hidden;

  &:before {
    @include imageOverlay();
  }
}

.featured-image__caption {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  transform: translateY(-100%);
  transition: transform .15s;
}

.featured-image__caption p {
  padding: $unit-xs;
  pointer-events: none;
  background-color: $featured-image-caption-background;
}

#featured-image-caption:target p,
.featured-image__caption.toggle--active p {
  pointer-events: auto;
}

.featured-image__toggle--show,
.featured-image__toggle--hide {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform: translateY(100%);

  &:focus-visible {
    outline-offset: -1px;
  }
}

.featured-image__toggle--hide {
  display: none;
}

#featured-image-caption:target,
.featured-image__caption.toggle--active {
  transform: translateY(0);

  .featured-image__toggle--show {
    display: none;
  }

  .featured-image__toggle--hide {
    display: block;
  }
}

.featured-image__toggle-content {
  display: flex;
  align-items: center;
  padding: $unit-s;

  line-height: 1.2em;
  color: $color-light;
  background-color: $featured-image-caption-background;

  .svg-icon {
    display: block;
    width: $featured-image-icon;
    height: $featured-image-icon;
    margin-right: auto;
  }
}
