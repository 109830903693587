.opinion-panel-form {
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
}

.opinion-panel-form > * {
  margin-bottom: $unit-s;
}

.opinion-panel-form .opinion-panel-form__email {
  flex-grow: 2;
  width: auto;
  min-width: 220px;
  margin-left: $unit-s;
}
