$search-input-height: 36px;

.search-form {
  position: relative;
  width: 100%;
  height: $search-input-height;
}

.search-form__search-field {
  display: block;
  width: 100%;
  height: $search-input-height;
  padding-top: 0.2em;
  padding-right: $search-input-height + $unit-m;
  padding-left: $unit-m;

  @include fontBase;
  font-size: 1.8rem;
  color: currentColor;

  background-color: transparent;
  box-shadow: 0 0 0 1px currentColor;
  border-radius: $border-radius-small;
  -webkit-appearance:none;
  appearance:none;

  &:focus {
   outline: none;
   box-shadow: 0 0 0 2px currentColor;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    box-shadow: 0 0 0 2px rgba($color-brand-light-shade-1, .3);
    @include font-smoothing(true);
    color: $color-light;

    &:focus {
      box-shadow: 0 0 0 2px $color-brand-light-shade-1;
     }
  }
}

.search-form__search-field::-webkit-search-decoration,
.search-form__search-field::-webkit-search-cancel-button,
.search-form__search-field::-webkit-search-results-button,
.search-form__search-field::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.search-form__submit {
  position: absolute;
  top: 0;
  right: 0;
  width: $search-input-height;
  height: $search-input-height;

  color: currentColor;
  background: none;
  outline: none;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  transition: background-color .1s linear, box-shadow .1s linear;

  &:hover,
  &:focus {
    background-color: $color-dark;
    color: $color-light;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    color: $color-brand-light-shade-2;

    &:hover,
    &:focus {
      background-color: $color-brand-light-shade-1;
      color: $color-brand-dark;
      box-shadow: 0 0 0 2px $color-brand-light-shade-1;
    }
  }
}

.search-form__submit svg {
  position: absolute;
  top: 5px;
  right: 5px;
  width: $search-input-height - 10px;
  height: $search-input-height - 10px;
}
