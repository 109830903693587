@use 'sass:math';
$bookmark-hitarea: 48px;

.bookmark {
  display: none;

  &.bookmark--enhanced {
    display: block;
  }
}

.bookmark__button {
  margin-inline-start: 8px;
}

.bookmark-icon--enabled {
  display: none;

  .bookmark--active & {
    display: block;
  }
}

.bookmark-icon--disabled {
  display: block;

  .bookmark--active & {
      display: none;
  }
}

// ============================= smallBookmark
.bookmark--small {
  position: relative;
  width: 30px;
  height: 30px;

  color: inherit;
  background: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
}

.bookmark--small .bookmark-icon--disabled,
.bookmark--small .bookmark-icon--enabled {
  width: $icon-size-small;
  height: $icon-size-small;
}


.bookmark--small__hitarea {
  position: absolute;
  top: calc(50% - #{ math.div($bookmark-hitarea, 2) });
  left: calc(50% - #{ math.div($bookmark-hitarea, 2) });
  width: $bookmark-hitarea;
  height: $bookmark-hitarea;
  background-image: linear-gradient(currentColor, currentColor);
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;

  .bookmark--small:hover & {
    opacity: .25;
  }
}

.bookmark--small .svg-icon {
  position: absolute;
  top: calc(50% - #{$icon-size-small * .5});
  left: calc(50% - #{$icon-size-small * .5});
  width: $icon-size-small;
  height: $icon-size-small;
}
