.featured-articles {
  position: relative;
  background-color: $color-brand-light-shade-1;
  overflow: hidden;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }

  @media screen and (max-width: ($breakpoint-800 - 1)) {
    background-color: $color-brand-light-shade-1;
  }
}

.featured-articles__header a {
  color: inherit;

  &:hover,
  &:focus {
    color: $color-dark;
  }
}

.featured-articles__primary {
  overflow: visible;

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    display: flex;
    align-items: flex-end;
    margin-top: $card-meta-offset;
  }
}

.featured-articles__primary-image {
  display: block;
  position: relative;
  z-index: 1;
  width: calc(100% + #{$unit-s * 2});
  margin-right: -$unit-s;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-800) {
    width: 33.33% * 2.5;
    margin-right: auto;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    width: 62.5%;
    order: 2;
  }
}

.featured-articles__primary-image:before {
  content: '';
  display: block;
  // ratio: 3:2
  padding-top: 66.66%;
}

.featured-articles__primary-image:after {
  @media screen and (max-width: ($desktop-app-menu-breakpoint - 1px)) {
    @include imageOverlay();
  }
}

.featured-articles__primary-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  transition: transform .25s;
  will-change: transform;
}

.featured-articles__primary-image:hover img,
.featured-articles__primary-image:focus img {
  transform: scale(1.05);
}

.featured-articles__primary-caption {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding-bottom: $unit-s;

  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }

  @media screen and (min-width: $breakpoint-600) {
    width: 75%;
  }

  @media screen and (min-width: $breakpoint-800) {
    width: 66.66%;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    width: 50%;
    margin-left: -12.5%;
    padding-bottom: $unit-m;
    order: 1;
  }

  @media screen and (min-width: $breakpoint-1000) {
    padding-bottom: $unit-l;
  }
}

.featured-articles__primary--video .featured-articles__primary-caption,
.featured-articles__primary--image .featured-articles__primary-caption,
.featured-articles__primary--gallery .featured-articles__primary-caption {
  background-color: $color-dark-muted;
}

.featured-articles__primary--audio .featured-articles__primary-caption {
  background-color: $color-yellow-shade-1;
}

.featured-articles__primary--chat .featured-articles__primary-caption,
.featured-articles__primary--quote .featured-articles__primary-caption {
  background-color: $color-green-shade-1;
}

.featured-articles__primary-caption .card-meta {
  order: 1;
}

.featured-articles__primary-caption .card-meta__info {
  color: $color-light;

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    width: 25%;
    margin-left: auto;
    color: $color-brand-dark;

    @include darkTheme() {
      color: $color-light;
    }
  }
}

.featured-articles__primary-caption .card-meta__info .bookmark {
  color: $color-brand-dark;
  left: $unit-s;
  position: absolute;
  top: $unit-s;

  @include darkTheme() {
    color: $color-light;
  }
}

.featured-articles__primary-caption a,
.featured-articles__primary-excerpt {
  padding-top: $unit-s;
  padding-right: $unit-s;
  padding-left: $unit-s;

  @media screen and (min-width: $breakpoint-600) {
    padding-top: $unit-m;
    padding-left: $unit-m;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    padding-right: $unit-m;
    padding-left: $unit-l;
  }

  @media screen and (min-width: $breakpoint-1000) {
    padding-left: $unit-xl;
  }

  @media screen and (min-width: $breakpoint-1400) {
    padding-left: $unit-xxl;
  }
}

.featured-articles__primary-caption a {
  order: 2;
}

.featured-articles__primary-excerpt {
  order: 3;
  color: $color-dark;

  @include darkTheme() {
    color: $color-brand-light-shade-1;
  }
}

.featured-articles__primary--video .featured-articles__primary-excerpt,
.featured-articles__primary--image .featured-articles__primary-excerpt,
.featured-articles__primary--gallery .featured-articles__primary-excerpt {
  color: $color-light;
  @include font-smoothing(true);
}

.featured-articles__title {
  line-height: 1.5em;
  line-height: 1.35em;
  color: $color-brand-dark;
  font-variation-settings: "wght" 600, "SHAR" 0, "SERI" 0, "KASH" 5;
  transition: color .1s linear;
  padding-left: 40px;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  @media screen and (min-width: $breakpoint-400) {
    font-size: 2.4rem;
  }

  @media screen and (min-width: $breakpoint-600) {
    font-size: 2.8rem;
    padding-left: $unit-l;
  }

  @media screen and (min-width: $breakpoint-800) {
    font-size: 3.2rem;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    padding-left: $unit-m;
  }

  @media screen and (min-width: $breakpoint-1000) {
    padding-left: 0;
  }

  @media screen and (min-width: $breakpoint-1200) {
    font-size: 3.6rem;
  }

  .featured-articles__primary-caption:hover &,
  .featured-articles__primary-caption:focus & {
    color: $color-dark;

    @include darkTheme() {
      color: $color-brand-light;
    }
  }
}

.featured-articles__primary--video .featured-articles__title,
.featured-articles__primary--image .featured-articles__title,
.featured-articles__primary--gallery .featured-articles__title {
  color: $color-light;
  @include font-smoothing(true);
}

.featured-articles__primary--audio .featured-articles__title {
  color: $color-dark;
}

.featured-articles__secondaries {
  position: relative;
  margin-top: $unit-m;

  @media screen and (min-width: $breakpoint-600) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    margin-top: $unit-l;
  }

  @media screen and (min-width: $breakpoint-1200) {
    margin-top: $unit-xl;
  }
}

.featured-articles__secondaries:before {
  content: '';
  position: absolute;
  top: 0;
  left: calc(50% - 50vw);
  width: 100vw;
  padding-top: $card-img-ratio;

  background-color: $color-light;

  transform-origin: 50% 0;
  transform: translateY(100%) scaleY(6);

  @include darkTheme() {
    background-color: $color-dark;
  }

  @media screen and (min-width: $breakpoint-600) {
    padding-top: $card-img-ratio * .5;
    transform: translateY(100%) scaleY(4);
  }

  @media screen and (min-width: $breakpoint-800) {
    padding-top: $card-img-ratio * .33;
    transform: translateY(100%) scaleY(2);
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    padding-top: $card-img-ratio * .25;
  }
}

.featured-articles__secondaries > * {
  & + * {
    margin-top: $unit-m;
  }

  @media screen and (min-width: $breakpoint-600) {
    width: 50%;

    &:nth-child(2) {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $breakpoint-800) {
    width: 33.33%;

    & + * {
      margin-top: 0;
    }

    &:nth-child(4) {
      display: none;
    }
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    width: 25%;

    &:nth-child(4) {
      display: flex;
    }

  }
}
