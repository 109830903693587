.internal-banners--primary {
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.internal-banners--secondary {
  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }
}

.internal-banners__list {
  list-style-type: none;
}

.internal-banners--count-1 {
  .internal-banners__item {
    width: 100%;
    max-width: 220px;
    margin-right: auto;
    margin-left: auto;

    @media all and (min-width: 500px) {
      max-width: 270px;
    }
  }
}

.internal-banners--count-2 {

  .internal-banners__list {
    display: flex;
    justify-content: space-between;

    @media all and (min-width: 500px) {
      justify-content: space-around;
    }
  }

  .internal-banners__item {
    width: calc(50% - #{$unit-l});
    max-width: 170px;

    @media all and (min-width: 1000px) {
      max-width: 220px;
    }
  }
}

.internal-banners--count-3 {

  .internal-banners__list {
    @media all and (min-width: 500px) {
      display: flex;
      justify-content: space-around;
    }
  }

  .internal-banners__item {
    width: 100%;
    max-width: 170px;
    margin-right: auto;
    margin-left: auto;

    &:not(:first-child) {
      margin-top: $unit-m;
    }

    @media all and (min-width: 500px) {
      width: calc(33.33% - #{$unit-l});
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;

      &:not(:first-child) {
        margin-top: 0;
      }
    }

    @media all and (min-width: 1000px) {
      max-width: 220px;
    }
  }
}

.internal-banners__link {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;

  box-shadow: 0 10px 8px rgba(black, .02);
  border-radius: 3px;
  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark-muted;
  }

  transition: transform .1s;

  &:hover,
  &:focus {
    transform: scale(1.05);
  }
}

.internal-banners__image {
  display: block;
  width: 100%;
  height: auto;
}
