.card-meta {
  display: flex;
  align-items: flex-start;
  margin-top: -$card-meta-offset;
  min-height: $card-meta-offset;
}

.card-meta__timestamp {
  width: 50%;
  min-height: $card-meta-offset;
  padding-top: $unit-s;
  padding-right: $unit-s;
  padding-left: $unit-s;

  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }

  .card-primary--brand-light & {
    background-color: $color-brand-light-shade-1;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    padding-right: $unit-m;
  }
}

.card-meta--video .card-meta__timestamp,
.card-meta--image .card-meta__timestamp,
.card-meta--gallery .card-meta__timestamp {
  background-color: $color-dark-muted;
  color: $color-light;
  @include font-smoothing(true);

  @include darkTheme() {
    background-color: $color-dark-muted--darken;
  }
}

.card-meta--audio .card-meta__timestamp {
  background-color: $color-yellow-shade-1;

  @include darkTheme() {
    background-color: $color-yellow-shade-2--darken;
  }
}

.card-meta--chat .card-meta__timestamp,
.card-meta--quote .card-meta__timestamp {
  background-color: $color-green-shade-1;

  @include darkTheme() {
    background-color: $color-green-shade-2--darken;
  }
}

.card-meta__info {
  width: 50%;
  display: flex;
  justify-content: space-between;
  max-height: $card-meta-offset;
  padding-right: $unit-s;

  &:only-child {
    width: 100%;
  }
}

.card-meta__info .reading-time {
  padding-top: $unit-xs;
}

.card-meta__info .bookmark:only-child {
  margin-right: auto;
}
