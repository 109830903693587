.not-found__actions {
  margin-top: $unit-l;

  @media screen and (min-width: $breakpoint-600) {
    margin-top: $unit-xl;
    display: flex;
    justify-content: center;
  }
}

.not-found__actions > * {
  display: block;
  width: 100%;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;

  &:not(:first-child) {
    margin-top: $unit-s;
  }

  @media screen and (min-width: $breakpoint-600) {
    display: inline-block;
    width: auto;
    margin-right: $unit-m;
    margin-left: $unit-m;

    &:not(:first-child) {
      margin-top: 0;
    }
  }
}
