.link-title {
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
  color: inherit;

  &:hover,
  &:focus {
    color: $color-dark;
  }

  @include darkTheme() {
    &:hover,
    &:focus {
      color: $color-brand-light;
    }
  }
}

.link-title span {
  &:nth-child(1) {
    padding-left: $unit-m;
  }

  &:nth-child(2) {
    position: relative;
    padding-top: 0.5rem;

    @media screen and (min-width: $breakpoint-800) {
      white-space: nowrap;
    }
  }
}

.link-title span:nth-child(2):before {
  content: '|';
  display: inline-block;
  padding-left: $unit-m;
}
