.section-header {
  position: relative;
  z-index: 3;
}

.section-header__content {
  padding-top: $unit-s;
  padding-bottom: $unit-s;

  .section-header--theme-false & {
    padding-bottom: 0;
    border-top: 1px solid $color-border;
  }
}

.section-header--theme-dark {
  background-color: $color-brand-dark-shade-1;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }
}

.section-header--theme-light {
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.section-header--theme-yellow {
  background-color: $color-yellow-shade-1;

  @include darkTheme() {
    background-color: $color-yellow-shade-1--darken;
  }
}

.section-header--theme-blue {
  background-color: $color-blue-shade-1;

  @include darkTheme() {
    background-color: $color-blue-shade-1--darken;
  }
}

.section-header--theme-grey {
  .h2 {
    color: $color-light;
    @include font-smoothing(true);
  }

  .link-title:hover,
  .link-title:focus {
    color: $color-brand-light;
  }
}
