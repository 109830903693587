.search-results__wrapper:not(.search-results--is-pending) .loader {
  display: none;
}

.search-results__results {

  .search-results__wrapper:not(.search-results--has-results) &,
  .search-results__wrapper.search-results--is-pending & {
    display: none;
  }
}

.search-results__error {
  .search-results__wrapper:not(.search-results--has-error) & {
    display: none;
  }
}

.search-results__list {
  margin-top: $unit-l;
}

.search-results__result {
  & + & {
    margin-top: $unit-xl;
  }
}

.search-results__result-link {
  color: $color-brand-dark;

  &:hover {
    color: $color-dark;

    @include darkTheme() {
      color: $color-brand-light;
    }
  }

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }
}

.search-results__result-text b {
  @include bold('font-base');
}

.search-results__pagination {
  display: flex;
  justify-content: center;
  gap: $unit-m;
}

.search-results__pagination-link {
  display: flex;
  align-items: center;
  gap: $unit-s;
  padding-right: $unit-s;
  padding-left: $unit-s;

  border-radius: $border-radius-small;
  font-family: inherit;
  color: currentColor;

  &:hover {
    background-color: $color-brand-dark-shade-2;

    @include darkTheme() {
      background-color: $color-black;
    }
  }
}

.search-results__pagination-link .svg-icon {
  display: block;
  width: 1em;
  height: 1em;

  transition: transform .1s;
}

.search-results__pagination-link--prev:hover .svg-icon,
.search-results__pagination-link--prev:focus .svg-icon {
  transform: translateX(25%);
}

.search-results__pagination-link--next:hover .svg-icon,
.search-results__pagination-link--next:focus .svg-icon {
  transform: translateX(-25%);
}

.search-results__page-index {
  margin-top: $unit-m;
}

.search-results-count:empty + .search-results-text {
  display: none;
}
