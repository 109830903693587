.opinion-panel-banner__cols {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: $unit-xl;
}

.opinion-panel-banner__chart svg {
  display: block;
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
}

.opinion-panel-banner__chart figcaption {
  margin-top: $unit-m;
}

.opinion-panel-banner__actions {
  display: flex;
  justify-content: center;
  margin-top: $unit-xl;
}
