html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  //this causes you to put pixels easily in rem - 24px = 2.4rem
  font-size: 62.5%;
}

.typo {
  @include fontBase;
  color: $color-dark;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;

  @include darkTheme() {
    color: $color-brand-light-shade-1;
  }

  font-size: 1.7rem;
  line-height: 1.8em;

  @media screen and (min-width: $breakpoint-600) {
    font-size: 1.9rem;
  }

  @media screen and (min-width: $breakpoint-1000) {
    font-size: 2.1rem;
  }
}

.typo-accent {
  @include fontAccent;

  font-size: 1.7rem;

  @media screen and (min-width: $breakpoint-600) {
    font-size: 1.9rem;
  }

  @media screen and (min-width: $breakpoint-1000) {
    font-size: 2.1rem;
  }
}

// ================================= sizes
.typo--small {
  font-size: 1.4rem;
  font-variation-settings: "wght" 400, "SHAR" 0, "SERI" 0, "KASH" 5;

  @media screen and (min-width: $breakpoint-600) {
    font-size: 1.6rem;
  }
}

.typo-accent--small {
  @include fontAccent;
  font-size: 1.6rem;
  font-variation-settings: "wght" 300, "wdth" 75, "slnt" 0;

  @media screen and (min-width: $breakpoint-600) {
    font-size: 1.8rem;
  }
}

.typo-accent--xsmall {
  @include fontAccent;
  font-size: 1.4rem;
  font-variation-settings: "wght" 300, "wdth" 75, "slnt" 0;
}

.typo--large {
  font-size: 1.9rem;
  line-height: 1.7em;

  @media screen and (min-width: $breakpoint-400) {
    font-size: 2.1rem;
  }

  @media screen and (min-width: $breakpoint-600) {
    font-size: 2.3rem;
  }
}

.typo--latin {
  font-size: 0.85em;
}

// ================================= weights
.typo--weight-light {
  font-variation-settings: "wght" 200, "SHAR" 0, "SERI" 0, "KASH" 0;
}

.typo-accent--weight-light {
  font-variation-settings: "wght" 200, "wdth" 75, "slnt" 0;
}

.typo--weight-latin-light {
  font-variation-settings: "wght" 300;
}

.typo--weight-bold {
  @include bold('font-base');
}

.typo-accent--weight-bold {
  @include bold('font-accent');
}

// ================================= alignment
.typo--align-center {
  text-align: center;
}

// ================================= colors
.typo--color-dark {
  color: $color-dark;
  @include font-smoothing(false);

  @include darkTheme() {
    color: $color-brand-light;
    @include font-smoothing(true);
  }
}

.typo--color-dark-muted {
  color: $color-dark-muted;
  @include font-smoothing(false);
}

.typo--color-brand-dark {
  color: $color-brand-dark;
  @include font-smoothing(false);

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }
}
.typo--color-brand-dark-shade {
  color: $color-brand-dark-shade-2;
  @include font-smoothing(false);
}

.typo--color-light {
  color: $color-light;
  @include font-smoothing(true);
}

// ================================= headings

.h1 {
  font-size: 2.8rem;
  line-height: 1.4em;
  font-variation-settings: "wght" 700, "SHAR" 0, "SERI" 0, "KASH" 5;
  color: $color-brand-dark;

  @media screen and (min-width: $breakpoint-400) {
    font-size: 3rem;
  }

  @media screen and (min-width: $breakpoint-800) {
    font-size: 3.2rem;
  }

  @media screen and (min-width: $breakpoint-1000) {
    font-size: 3.4rem;
  }
}

.h2 {
  font-size: 3rem;
  line-height: 1.5em;
  font-variation-settings: "wght" 700, "SHAR" 0, "SERI" 0, "KASH" 5;
  color: $color-brand-dark;

  @media screen and (min-width: $breakpoint-400) {
    font-size: 3.2rem;
  }
}

.h3 {
  font-size: 2.2rem;
  line-height: 1.5em;
  font-variation-settings: "wght" 650, "SHAR" 0, "SERI" 0, "KASH" 5;
  color: $color-brand-dark;

  @media screen and (min-width: $breakpoint-400) {
    font-size: 2.4rem;
  }
}

.h1,
.h2,
.h3 {
  color: $color-brand-dark;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }
}

.h4 {
  @include fontAccent;
  font-size: 1.5rem;
  line-height: 1.2em;
  font-variation-settings: "wght" 400, "wdth" 75, "slnt" 0;
  color: $color-dark;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  @media screen and (min-width: $breakpoint-600) {
    font-size: 1.8rem;
  }
}

strong {
  .typo & {
    @include bold('font-base');
  }

  .typo-accent & {
    @include bold('font-accent');
  }
}

.link {
  color: $color-dark;
  text-decoration: none;
  @include bold('font-base');

  @include darkTheme() {
   color: $color-light;
   @include font-smoothing(true);
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
