.social-share__title {
  padding-top: $unit-xs;
  padding-right: $unit-s;
  padding-left: $unit-s;
}

.social-share__list {
  padding-right: $unit-s;
  padding-bottom: $unit-s;
  padding-left: $unit-xs;
  display: flex;
  flex-wrap: wrap;
}

.social-share__list li {
  margin-top: $unit-xs;
  margin-left: $unit-xs;
}

.social-share__list li a {
  display: block;
}

.social-share__list li button {
  display: block;
}

.social-share__print {
  display: none;

  .enhanced & {
    display: block;
  }
}
