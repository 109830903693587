$button-close-icon-size: 15px;

.notification-message {
  padding-top: 40px;
}

.notification-message__body {
  line-height: 1.6;
}

.notification-message__body a:not([class]) {
  color: $color-brand-dark--darken;
}


.notification-message__close {
  position: absolute;
  top: 0;
  right: 0;
  width: $square-button-size;
  height: $square-button-size;

  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  color: $color-dark;

  transition: background-color .15s linear;

  &:hover,
  &:focus {
    background-color: rgba($color-dark, 0.15);
  }
}

.notification-message__close svg {
  position: absolute;
  top: calc(50% - #{$button-close-icon-size * .5});
  right: calc(50% - #{$button-close-icon-size * .5});
  width: $button-close-icon-size;
  height: $button-close-icon-size;
}

