.button {
  display: inline-block;
  padding: $unit-xs * 1.3 $unit-l $unit-xs $unit-l;

  background: none;
  border: 0;
  border-radius: $border-radius-small;
  cursor: pointer;

  @include fontAccent;
  font-variation-settings: "wght" 550, "wdth" 75, "slnt" 0;
  text-align: center;
  text-decoration: none;
  font-size: inherit;
  color: $color-light;
  @include font-smoothing(true);
  white-space: nowrap;

  transition: color .1s linear, background-color .1s linear;

  &.button--outline {
    background-color: transparent;
  }

  &[disabled] {
    cursor: default;
  }
}

.button--small {
  padding: $unit-xs * .4 $unit-m $unit-xs $unit-m;
  font-size: 1.4rem;
  line-height: 1.35em;

  @media screen and (min-width: $breakpoint-600) {
    font-size: 1.6rem;
  }
}

.button--wide {
  width: 100%;
}

.button--dark {
  color: $color-dark;
}

.button--primary {
  background-color: $color-blue;

  @include darkTheme() {
    background-color: $color-light;
    color: $color-brand-dark;
  }

  &.button--outline {
    background-color: transparent;
    color: $color-blue;
    box-shadow: inset 0 0 0 2px $color-blue;

    @include darkTheme() {
      background-color: transparent;
      color: $color-light;
      box-shadow: inset 0 0 0 2px $color-light;
    }
  }

  &:hover,
  &:focus {
    background-color: $color-blue-shade-1;

    @include darkTheme() {
      background-color: $color-brand-dark-shade-1;
    }
  }

  &.button--outline:hover,
  &.button--outline:focus {
    background-color: $color-blue-shade-2;

    @include darkTheme() {
      background-color: $color-light;
      color: $color-brand-dark;
    }
  }

  &[disabled] {
    color: $color-blue-shade-1;
    background-color: $color-blue-shade-2;
    box-shadow: inset 0 0 0 2px $color-blue;
  }
}

.button--secondary {
  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-light;
    color: $color-dark;
  }

  &.button--outline {
    color: $color-brand-dark;
    box-shadow: inset 0 0 0 2px $color-brand-dark;

    @include darkTheme() {
      background-color: transparent;
      color: $color-brand-light;
      @include font-smoothing(true);
      box-shadow: inset 0 0 0 2px $color-brand-light;
    }
  }

  &:hover,
  &:focus {
    background-color: $color-brand-dark-shade-1;

    @include darkTheme() {
      background-color: $color-light;
    }
  }

  &.button--outline:hover,
  &.button--outline:focus {
    background-color: $color-brand-dark-shade-2;

    @include darkTheme() {
      color: $color-dark;
      background-color: $color-brand-light;
    }
  }

  &[disabled] {
    color: $color-brand-dark-shade-1;
    background-color: $color-brand-dark-shade-2;
    box-shadow: inset 0 0 0 2px $color-brand-dark;
  }
}

.button--tertiary {
  background-color: $color-dark;

  @include darkTheme() {
    background-color: $color-light;
    color: $color-dark;
  }

  &.button--outline {
    color: $color-dark;
    box-shadow: inset 0 0 0 2px $color-dark;

    @include darkTheme() {
      background-color: transparent;
      color: $color-light;
      box-shadow: inset 0 0 0 2px $color-light;
      @include font-smoothing(true);
    }
  }

  &:hover,
  &:focus {
    background-color: $color-dark-muted;

    @include darkTheme() {
      background-color: $color-dark-muted;
    }
  }

  &.button--outline:hover,
  &.button--outline:focus {
    background-color: $color-grey-medium;

    @include darkTheme() {
      background-color: $color-dark-muted;
      box-shadow: inset 0 0 0 2px $color-dark-muted;
    }
  }

  &[disabled] {
    color: $color-dark-muted;
    background-color: $color-grey-medium;
    box-shadow: inset 0 0 0 2px $color-dark-muted;
  }
}

.button--alert {
  background-color: $color-red;

  &.button--outline {
    color: $color-red;
    box-shadow: inset 0 0 0 1px $color-red;
  }

  &:hover,
  &:focus {
    background-color: $color-red-shade-1;
    color: $color-red;
  }

  &.button--outline:hover,
  &.button--outline:focus {
    background-color: $color-red-shade-2;
  }

  &[disabled]  {
    color: $color-red-shade-1;
    background-color: $color-red-shade-2;
    box-shadow: inset 0 0 0 1px $color-red-shade-1;
  }
}

.button--success {
  background-color: $color-green--darken;
  color: $color-light;

  &:hover,
  &:focus {
    background-color: $color-green;
  }
}

.button--icon {
  width: $icon-size;
  height: $icon-size;
  min-width: $icon-size;
  min-height: $icon-size;
  padding: 7px;

  background-color: $color-brand-light-shade-1;
  color: $color-dark;
  border-radius: 0;
  transition: background-color .1s linear;

  &:hover {
    background-color: $color-brand-light;
  }
}

.button--icon .svg-icon {
  width: calc(#{$icon-size - 14px});
  height: calc(#{$icon-size - 14px});
}

.button--icon-blend {
  background-color: transparent;
  color: $color-blue;
  transition: color .1s linear;

  @include darkTheme() {
    color: $color-light;
  }

  &:hover,
  &:focus {
    color: $color-blue-shade-1;
    background-color: transparent;

    @include darkTheme() {
      color: $color-brand-dark-shade-1;
    }
  }
}

.button--text-icon .svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: $icon-size-medium;
  height: $icon-size-medium;
  margin-right: $unit-xs;
}

.button--silent {
  display: inline-block;
  padding: 0;

  background: none;
  border: 0;
  cursor: pointer;

  @include fontAccent;
  font-variation-settings: "wght" 550, "wdth" 75, "slnt" 0;
  text-align: center;
  text-decoration: none;
  color: inherit;

  @include bold('font-accent');
  background-image: linear-gradient(currentColor, currentColor);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: center bottom;

  &:hover,
  &:focus {
    background-image: none;
  }

  &:not(.button--small) {
    font-size: inherit;
  }
}

.button--pill {
  color: $color-brand-dark;
  border-radius: 20px;
  background-color: $color-brand-dark-shade-2;

  @include darkTheme() {
    color: $color-light;
    background-color: $color-brand-dark-shade-1--darken;
    @include font-smoothing(true);
  }

  &:hover,
  &:focus {
    background-color: $color-brand-dark-shade-1;
    color: $color-light;

    @include darkTheme() {
      background-color: $color-brand-dark--darken;
    }
  }
}
