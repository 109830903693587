[data-section-filter-results] {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease;
}

[data-section-filter-results].is-loading {
  opacity: .5;
  pointer-events: none;
}

[data-section-filter-result-list] {
  &:not(.is-active) {
    display: none;
  }

  &.is-active ~ [data-section-filter-original-results] {
    display: none;
  }
}

.section-filter__form {
  &:not(.is-enhanced) {
    display: none;
  }

  @media all and (min-width: $breakpoint-600) {
    display: flex;
    align-items: flex-end;
  }
}

.section-filter__options {
  display: flex;

  @media all and (max-width: $breakpoint-600 - 1px) {
    margin-bottom: $unit-s;
    justify-content: space-between;
  }
}

.section-filter__option {
  @media all and (max-width: $breakpoint-600 - 1px) {
    width: calc(50% - #{$unit-xs});
  }

  @media all and (min-width: $breakpoint-600) {
    width: 200px;
    margin-left: $unit-s;
  }
}

.section-filter__select {
  display: block;
  width: 100%;
  padding: 10px $unit-m 7px $unit-m;

  @include fontAccent();
  @include bold('font-accent');
  font-size: 2rem;
  line-height: 1.3;


  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  border: none;
  background-color: $color-grey-light;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke-width='3px' stroke='black'><path d='M30 12 L16 24 2 12'/></svg>");
  background-repeat: no-repeat;
  background-position: left 10px top 45%;
  background-size: 14px 14px;
  border-radius: $border-radius-small;
}

.section-filter__label {
  @include fontAccent();
  font-size: 1.6rem;
  line-height: 1.2em;
}

.section-filter__no-results {
  margin-top: $unit-m;
  display: none;

  &.is-visible {
    display: block;
  }
}
