.post__pullquote-inner {
  padding-right: $unit-l;
  border-right: 4px solid $color-brand-dark;

  @include darkTheme() {
    border-right: 4px solid $color-brand-light;
  }

  @media screen and (min-width: $breakpoint-800) {
    padding-right: $unit-xl;
  }
}

.post__pullquote-actions {
  list-style: none;
  display: flex;

  > * + * {
    margin-right: $unit-xs;
  }
}
