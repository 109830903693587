@import './search-bar.variables';

.search-bar {
  display: flex;
  height: $search-bar-height;
  padding: $unit-s;

  background-color: $color-light;
  border-radius: 2px;
  box-shadow:
    inset 0 0 0 1px $color-grey-medium,
    0 0 20px rgba(black, .1);
  transition: box-shadow .1s linear;

  &:focus-within {
    box-shadow:
      inset 0 0 0 2px $color-brand-dark-shade-1,
      0 0 20px rgba(black, .1);
  }
}

.search-bar__input {
  display: block;
  flex-grow: 2;
  min-width: 1px;
  margin-left: $unit-s;
  padding-right: $unit-s;

  @include fontAccent;
  font-size: 2rem;
  outline: none;
  background-color: transparent;

  @media screen and (min-width: $breakpoint-800) {
    font-size: 2.5rem;
  }

  // remove the native 'x'
  &::-ms-clear { display: none; width : 0; height: 0; }
  &::-ms-reveal { display: none; width : 0; height: 0; }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration { display: none; }
}

.search-bar__submit {
  @media screen and (max-width: $breakpoint-800 - 1px) {
    padding-right: $unit-m;
    padding-left: $unit-m;
  }
}
