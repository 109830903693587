.comment-list__item:not(:last-child) {
  margin-bottom: $unit-l;
}

.comment-list__item-meta {
  display: flex;
  align-items: center;
}

.comment-list__item-title {
  margin-bottom: $unit-m;
}

.comment-list__item-author {
  display: flex;
}

.comment-list__item-author:after {
  content: '|';
  display: flex;
  padding: 0 $unit-s;
}
