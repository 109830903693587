.header-banner {
  position: relative;
  flex-grow: 2;
  order: 3;
  z-index: 1;
  width: 100%;
  height: $header-banner-height-mobile;
  padding-right: $unit-xs;
  padding-bottom: $unit-xs;

  display: flex;
  align-items: flex-end;

  overflow: hidden;
  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }

  @media screen and (min-width: $breakpoint-800) {
    padding-right: $unit-s;
    padding-bottom: $unit-s;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    height: $header-banner-height-desktop;
  }
}

.header-banner__image {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: auto;
  height: 100%;
  transform: translateX(-50%);
}

.header-banner__title {
  position: relative;
  z-index: 2;
  width: calc(50vw - #{$logo-header-width-mobile * .5 + $unit-m});
  max-width: 350px;

  font-size: 1.5rem;
  line-height: 1.3em;
  font-variation-settings: "wght" 300, "SHAR" 100, "SERI" 100, "KASH" 0;
  color: $color-light;
  @include font-smoothing(true);

  @media screen and (min-width: $breakpoint-400) {
    font-size: 1.8rem;
  }

  @media screen and (min-width: $breakpoint-600) {
    font-size: 2.2rem;
  }

  @media screen and (min-width: $breakpoint-800) {
    font-size: 2.6rem;
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    width: calc(50vw - #{$logo-header-width-desktop * .5 + $unit-m});
    font-size: 2.7rem;
    font-variation-settings: "wght" 200, "SHAR" 100, "SERI" 100, "KASH" 20;
  }
}
