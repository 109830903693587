.blocks {
  margin-top: $unit-m;
  margin-bottom: $unit-m;

  @media all and (min-width: $breakpoint-1000) {
    margin-bottom: $unit-l;
  }
}

.blocks__unit {
  &:not(:first-child) {
    margin-top: $unit-m;
  }

  @media screen and (min-width: $breakpoint-600) {
    &:not(:first-child) {
      margin-top: $unit-l;
    }
  }
}

.blocks__unit--clear {
  clear: both;
}

.blocks--emphatic,
.blocks--indented {
  padding-right: $unit-l;

  @media screen and (min-width: $breakpoint-600) {
    padding-right: $unit-xl;

    // posts can contain floating embeds
    // we don't want indented blocks to be next to them
    clear: both;
  }

  @media screen and (min-width: $breakpoint-800) {
    padding-right: $unit-xxl;
  }
}

.blocks--emphatic > * {
  padding: $unit-m;
  background-color: $color-blue-shade-2;

  @include darkTheme() {
    background-color: $color-blue-shade-2--darken;
  }

  @media screen and (min-width: $breakpoint-600) {
    padding: $unit-l;
  }
}

.blocks--emphatic + .blocks--emphatic,
.blocks--indented + .blocks--indented {
  margin-top: 0;

  > * {
    padding-top: 0;
  }
}

.blocks__unit p a {
  color: $color-blue;
  text-decoration: underline;

  &:hover {
    color: $color-brand-dark;
  }

  @include darkTheme() {
    color: $color-blue-shade-1;
    @include font-smoothing(true);

    &:hover {
      color: $color-blue-shade-2;
    }
  }
}
