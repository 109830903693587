@use 'sass:math';
$loader-dot-size: 12px;
$loader-duration: 1.4s;
$loader-stagger: .25s;

.loader {
  display: flex;
  justify-content: center;
}

.loader__dot {
  width: $loader-dot-size;
  height: $loader-dot-size;
  margin-right: math.div($loader-dot-size, 2);
  margin-left: math.div($loader-dot-size, 2);

  background-color: $color-brand-dark;
  border-radius: 50%;

  animation: blink $loader-duration infinite;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: -$loader-stagger * $i;
    }
  }
}

@keyframes blink {
  0%, 100% { transform: scale(1); opacity: 1; }
  50% { transform: scale(.3); opacity: 0; }
}
