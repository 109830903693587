@use 'sass:math';

.modal {
  display: none;
}

.modal--open {
  display: block;
}

.modal__dialog {
  position: fixed;
  top: 50%;
  inset-inline-start: 50%;
  transform: translate(50%, -50%);
  z-index: 1000;
  color: inherit;
  border: none;
  height: 100%;
  width: 100%;
  background-color: $color-brand-light-shade-2;

  @media (min-width: $breakpoint-600) {
    margin-block: $unit-m;
    height: fit-content;
    max-height: calc(100% - #{ math.div($unit-m, 2)});
    width: clamp(min(300px, 100%), 80%, 1000px);
    box-shadow: 0 20px 30px 0 rgba($color-dark, 0.3);
  }

  @include darkTheme() {
    background-color: $color-brand-light-shade-2--darken;
  }
}

.modal__dialog--enter {
  -webkit-animation: slide-in-bck-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bck-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1.2);
            transform: translateY(-50%) translateX(50%) scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1);
            transform: translateY(-50%) translateX(50%) scale(1);
    opacity: 1;
  }
}

@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1.2);
            transform: translateY(-50%) translateX(50%) scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1);
            transform: translateY(-50%) translateX(50%) scale(1);
    opacity: 1;
  }
}

.modal__dialog--leave {
  -webkit-animation: slide-out-bck-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-out-bck-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1);
            transform: translateY(-50%) translateX(50%) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1.5);
            transform: translateY(-50%) translateX(50%) scale(1.5);
    opacity: 0;
  }
}

@keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1);
            transform: translateY(-50%) translateX(50%) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(50%) scale(1.5);
            transform: translateY(-50%) translateX(50%) scale(1.5);
    opacity: 0;
  }
}

.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba($color-dark-muted--darken, .3);
}

.modal__backdrop--enter {
	-webkit-animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal__backdrop--leave {
	-webkit-animation: fade-out 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-out 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal__header {
  display: flex;
  justify-content: flex-end;
}

.modal__button {
  position: fixed;
  background-color: $color-brand-light-shade-2;

  @include darkTheme() {
    background-color: $color-brand-light-shade-2--darken;
    color: $color-light;
  }

  &:hover {
    @include darkTheme() {
      background-color: $color-brand-light;
    }
  }
}

.modal__close-icon {
  width: $icon-size-small;
  height: $icon-size-small;
  stroke-width: 2px;
}

.modal__main {
  padding: 40px 20px 55px 20px;
  overflow: auto;
  height: fit-content;
  max-height: 100vh;

  @media screen and (min-width: $breakpoint-800) {
    padding: 40px 120px 60px 120px;
  }
}
