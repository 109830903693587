.card-secondary {
  display: flex;
  align-items: flex-start;
}

.card-secondary__header {
  order: 2;
  width: 50%;
  min-width: 50%;
}

.card-secondary__header-anchor {
  display: flex;
  flex-direction: column;
  padding-right: $unit-m;
}

.card-secondary__title {
  order: 2;

  font-size: 1.7rem;
  line-height: 1.5em;
  color: $color-brand-dark;
  font-variation-settings: "wght" 600, "SHAR" 0, "SERI" 0, "KASH" 5;
  transition: color .1s linear;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  @media screen and (min-width: $breakpoint-600) {
    font-size: 2rem;
  }

  @media screen and (min-width: $breakpoint-1200) {
    font-size: 2.2rem;
  }

  .card-secondary:hover &,
  .card-secondary:focus & {
    color: $color-dark;

    @include darkTheme() {
      color: $color-brand-light;
    }
  }
}

.card-secondary__meta {
  display: block;
  margin-bottom: $unit-s;
  order: 1;
}

.card-secondary__image {
  position: relative;
  order: 1;
  width: calc(50% - #{$unit-m});
  min-width: calc(50% - #{$unit-m});
  margin-right: $unit-m;

  overflow: hidden;
  background-color: $color-grey-medium;

  @include darkTheme() {
    background-color: rgba(black, .2);
  }

  @media screen and (min-width: $breakpoint-800) {
    width: calc(50% - #{$unit-l});
    min-width: calc(50% - #{$unit-l});
    margin-right: $unit-l;
  }
}

.card-secondary__image:before {
  content: '';
  display: block;
  padding-top: $card-img-ratio;
}

.card-secondary__image:after {
  @include imageOverlay();
  z-index: 1;
}

.card-secondary__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  transition: transform .25s;
  will-change: transform;

  .card-secondary:hover &,
  .card-secondary:focus & {
    transform: scale(1.05);
  }
}

// specific card-meta implementation
.card-secondary .card-meta {
  position: relative;
  z-index: 2;
}

.card-secondary .card-meta__info {
  color: $color-light;
}

.card-secondary .card-meta__timestamp {
  padding-right: 0;
}

// variation small
.card-secondary--small .card-meta__info {
  width: 100%;
}

.card-secondary--small .card-meta__timestamp {
  display: none;
}
