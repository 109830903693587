[data-install-prompt] {
  display: none;

  &.is-active {
    display: block;
  }
}

.install-prompt {
  width: 100%;
  padding-bottom: $unit-l;
  background-image: linear-gradient($color-light, $color-light);
  background-size: 100% 38.2%;
  background-position: 50% 0;
  background-repeat: no-repeat;

  @include darkTheme() {
    background-image: linear-gradient($color-dark, $color-dark);
  }
}

.install-prompt__inner {
  padding: $unit-s;
  border-radius: 6px;
  background-color: $color-light;
  box-shadow:
    5px 2px 55px rgba(black, .15),
    2px 1px 5px rgba(black, .1);
  transform: rotate(-1.5deg);

  @include darkTheme() {
    background-color: $color-dark;
  }
}
