@font-face {
  font-family: "farhangvariable";
  src: url("../assets/fonts/FarhangVariable.woff2") format("woff2-variations"),
       url("../assets/fonts/FarhangRegular.woff2") format("woff2"),
       url("../assets/fonts/FarhangRegular.woff") format("woff");
  font-weight: 100 900;
  font-display: fallback;
}

@font-face {
  font-family: "anjomanweb";
  src: url("../assets/fonts/AnjomanVariable.woff2") format("woff2-variations"),
       url("../assets/fonts/Anjoman-CondensedSemiBold.woff2") format("woff2"),
       url("../assets/fonts/Anjoman-CondensedSemiBold.woff") format("woff");
  font-weight: 100 1000;
  font-display: fallback;
}
