@use 'sass:math';
@import '../../_includes/components/search-bar/search-bar.variables';

.search__top {
  position: relative;
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.search__top .grid-lines {
  z-index: 1;
}

.search__search-bar {
  z-index: 2;
  position: relative;
  bottom: math.div(-$search-bar-height, 2);
  margin-bottom: math.div($search-bar-height, 2);

  @media screen and (min-width: $breakpoint-800) {
    margin-top: $unit-m;
  }
}
