$mobile-navigation-animation-duration: .6s;
$burger-base: $quick-nav-height;
$burger-thickness: 2px;
$burger-offset: 14px;
$burger-duration: .15s;
$burger-delay: .1s;

.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: calc(100% - #{$quick-nav-height});
  height: calc(var(--vh) - #{$quick-nav-height});

  visibility: hidden;
  transform: translateX(-105%);
  transition:
    transform $mobile-navigation-animation-duration $ease,
    visibility 0s $mobile-navigation-animation-duration;

  &.toggle--active,
  &:target {
    visibility: visible;
    transform: translateX(0);
    transition:
      transform $mobile-navigation-animation-duration $ease,
      visibility 0s;
  }

  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    display: none;
  }
}

.mobile-navigation__scroller {
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-height: 100%;

  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.mobile-navigation__items {
  padding-top: $unit-l;
  padding-bottom: $unit-l;
}

.mobile-navigation__item {
  display: flex;
  flex-wrap: wrap;
  padding-right: $unit-s;
  padding-left: $unit-s;

  & + .mobile-navigation__item {
    padding-top: $unit-s;
  }
}

.mobile-navigation__item-toggle,
.mobile-navigation__item-link {
  color: $color-light;
  cursor: pointer;

  &:hover {
    color: $color-blue;
  }

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing();

    &:hover {
      color: $color-brand-light;
    }
  }
}

.mobile-navigation__item-toggle {
  position: relative;
  width: $icon-size;
  height: $icon-size;
}

.mobile-navigation__item-link {
  flex-grow: 2;
  padding-right: $unit-s;
}

.mobile-navigation__item-toggle .svg-icon {
  position: absolute;
  top: calc(50% - #{$icon-size-small * .6});
  left: calc(50% - #{$icon-size-small * .5});
  width: $icon-size-small;
  height: $icon-size-small;
}

.mobile-navigation__subitems {
  width: 100%;
  padding-right: $icon-size-small + $unit-m;

  display: none;

  &.toggle--active,
  &:target {
    display: block;
  }
}

.mobile-navigation__subitems.mobile-navigation__subitems--more {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: $unit-m;
  padding-top: $unit-m;
  padding-right: 0;

  border-top: 1px solid rgba($color-light, .15);
}

.mobile-navigation__subitem-link {
  padding-right: $icon-size + $unit-s;
  color: $color-dark;

  &:hover {
    color: $color-blue;
  }

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing();

    &:hover {
      color: $color-brand-light;
    }
  }
}

.mobile-navigation .desktop-navigation__subitem-link {
  color: $color-light;
  @include font-smoothing(true);
}

.mobile-navigation__search {
  padding-right: $unit-l;
  padding-left: $unit-l;
  flex-grow: 2;

  .search-form {
    color: $color-light;
  }
}

.mobile-navigation__footer {
  margin-top: $unit-m;
  text-align: center;
  padding-bottom: $unit-l;

  width: 100%;
  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }
}

.mobile-navigation__footer .social-links {
  padding-top: $unit-m;
  padding-bottom: $unit-m;
  border-bottom: 1px solid rgba($color-light, .15);
}

.mobile-navigation__footer-logo {
  width: 220px;
  height: 50px;
  margin: $unit-m auto 0 auto;
}

.mobile-navigation__footer-logo .svg-icon {
  color: $color-light;
}

.mobile-navigation__footer-links {
  margin-top: $unit-l;
}

.mobile-navigation__footer-link {
  display: block;
  text-align: center;
}

.mobile-navigation__hitarea {
  position: fixed;
  z-index: 35;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh) - #{$quick-nav-height});

  display: none;

  .mobile-navigation.toggle--active + & {
    display: block;
  }
}

// =========================================== toggle button
.mobile-navigation__toggle {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 60;
  width: $burger-base;
  height: $burger-base;

  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }
}

.mobile-navigation__toggle:before,
.mobile-navigation__toggle:after {
    content: '';
    position: absolute;
    background-color: $color-light;
}

.mobile-navigation__toggle:before {
  top: calc(50% - ( #{$burger-thickness} * .5 ));
  left: $burger-offset;
  width: calc( #{$burger-base} - ( #{$burger-offset} * 2 ));
  height: $burger-thickness;

  box-shadow:
      0 calc( #{$burger-thickness} * -3 ) 0 $color-light,
      0 calc( #{$burger-thickness} * 3 ) 0 $color-light;

  transition:
    transform $burger-duration ease-in-out,
    box-shadow $burger-duration $burger-delay ease-out;
}

.mobile-navigation__toggle:after {
  top: $burger-offset;
  left: calc(50% - ( #{$burger-thickness} * .5 ));
  width: $burger-thickness;
  height: calc( #{$burger-base} - ( #{$burger-offset} * 2 ));

  transform: scaleY(0);
  transition: transform $burger-duration ease-in;
}

.mobile-navigation__toggle--close {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.mobile-navigation__toggle--open {
  pointer-events: auto;
  opacity: 1;
}


.mobile-navigation:target ~ ,
.mobile-navigation.toggle--active ~ {

  .mobile-navigation__toggle:before {
    box-shadow:
        0 calc( #{$burger-thickness} * -8 ) 0 rgba($color-dark, 0),
        0 calc( #{$burger-thickness} * 8 ) 0 rgba($color-dark, 0);
    transform: rotate(45deg);
    transition: box-shadow $burger-duration ease-in;
  }

  .mobile-navigation__toggle:after {
    transform: scaleY(1) rotate(45deg);
    transition: transform $burger-duration $burger-delay ease-out;
  }

  .mobile-navigation__toggle--close {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
  }

  .mobile-navigation__toggle--open {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }
}


@media all and (min-width: $desktop-app-menu-breakpoint) {
  .mobile-navigation__toggle {
    display: none !important;
  }
}
