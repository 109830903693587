.social-links {
  display: flex;
  justify-content: center;

  li {
    margin-right: $unit-xs;
    margin-left: $unit-xs;
  }

  a {
    display: block;
  }
}
