.featured-video {
  position: relative;
  padding-bottom: $card-meta-offset;
  background-color: black; // default for youtube iframe background
}

.featured-video .embed-handler--decline {
  max-width: none;
  padding-right: $unit-m;
  padding-bottom: $unit-s;
  padding-left: $unit-m;
}

.featured-video__video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; // ratio 16:9
}

.featured-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.featured-video__caption {
  padding-right: $unit-m;
  padding-left: $unit-m;
}
