@use "sass:math";

.section-tertiary {
  position: relative;
}

.section-tertiary.section--theme-light {
  overflow: hidden;

  background-color: $color-brand-light-shade-1;
  background-image: linear-gradient($color-light, $color-light);
  // white top area
  background-size: 100% 200px;
  background-position: 50% 0;
  background-repeat: no-repeat;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
    background-image: linear-gradient($color-dark, $color-dark);
  }

  @media all and (min-width: $breakpoint-1000) {
    background-size: 100% 300px;
  }
}

.section-tertiary__content {
  position: relative;
}

.section-tertiary__title {
  margin-bottom: $unit-l;

  &:not(:first-child) {
    margin-top: $unit-l;
  }
}

.section-tertiary.section--theme-grey {
  .section-tertiary__title{
    color: $color-light;
    @include font-smoothing(true);
  }

  .link-title:hover,
  .link-title:focus {
    color: $color-brand-light;
  }
}

.section-tertiary__grid {
  position: relative;
}

.section-tertiary.section--theme-light .section-tertiary__grid:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: $card-img-ratio;
  background-color: $color-light;
  transform: scaleX(100);

  @include darkTheme() {
    background-color: $color-dark;
  }

  @media all and (min-width: $breakpoint-600) {
    padding-top: math.div($card-img-ratio, 2);
  }

  @media all and (min-width: $breakpoint-800) {
    padding-top: math.div($card-img-ratio, 3);
  }
}
