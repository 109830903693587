.post-aside {
  clear: both;
}

.post-aside__content {
  position: relative;
  width: 100%;
  margin-top: $unit-m;
  margin-bottom: $unit-m;

  @media screen and (min-width: $breakpoint-600) {
    float: right;
    max-width: 300px;
    margin-left: $unit-l;
  }

  @media screen and (min-width: $breakpoint-800) {
    max-width: 400px;
  }

  @media screen and (min-width: $breakpoint-1000) {
    margin-right: -100px;
    margin-top: $unit-l;
    margin-bottom: $unit-l;
  }

  @media screen and (min-width: $breakpoint-1200) {
    margin-right: -170px;
  }
}

.post-aside--align-left .post-aside__content {
  @media screen and (min-width: $breakpoint-600) {
    float: left;
    margin-right: $unit-l;
    margin-left: 0;
  }

  @media screen and (min-width: $breakpoint-1000) {
    margin-left: -100px;
  }

  @media screen and (min-width: $breakpoint-1200) {
    margin-left: -170px;
  }
}

.post-aside--align-right .post-aside__content {
  @media screen and (min-width: $breakpoint-600) {
    float: right;
    margin-right: 0;
    margin-left: $unit-l;
  }

  @media screen and (min-width: $breakpoint-1000) {
    margin-right: -100px;
  }

  @media screen and (min-width: $breakpoint-1200) {
    margin-right: -170px;
  }
}
