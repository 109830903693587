.embed-handler__parent {
  position: relative;
  min-height: 300px;
}

.embed-handler__parent--collapsed {
  min-height: 0;
}

.embed-handler--accept {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  padding: $base-unit;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  &.embed-handler--light {
    background-color: $color-brand-dark-shade-2;
  }

  &.embed-handler--dark {
    background-color: $color-brand-light;
  }

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }

  .embed-accepted & {
    display: none;
  }

  > * + * {
    margin-top: $unit-s;
  }
}


.embed-handler__icon {
  position: absolute;
  top: 0;
  right: 0;
  width: $icon-size;
  height: $icon-size;
  margin-top: 0;
  background-color: $color-brand-dark;
  color: $color-light;

  .svg-icon {
    position: absolute;
    top: calc(50% - #{$icon-size-small * .5});
    left: calc(50% - #{$icon-size-small * .5});
    width: $icon-size-small;
    height: $icon-size-small;
  }
}

.embed-handler--decline {
  html:not(.embed-accepted) & {
    display: none;
  }
}

