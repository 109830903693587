.support-us__radio-group {
  display: flex;
  flex-direction: column;
}

.support-us__radio-group-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $unit-s;
}

.support-us__radio-group-option {
  flex-wrap: nowrap;

  input[type="radio"]:focus-visible ~ label,
  input[type="radio"]:checked ~ label {
    background-color: $color-brand-dark;
  }
}

.support-us__number-input-wrapper {
  display: inline-flex;
  align-self: flex-start;
  position: relative;

  &::before {
    content: '$';
    margin-inline-end: $unit-s;
  }
}

.support-us__number-input {
  max-width: 10rem;
}

