@import "./_includes/components/app-core/variables/variables";
@import "./_includes/components/app-core/mixins";
@import "./_includes/components/app-core/reset";

html {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:before {
  box-sizing: inherit;
}

*:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

@import "./_includes/components/app-core/helpers";
@import "./_includes/components/app-core/fonts";
@import "./_includes/components/app-core/buttons";
@import "./_includes/components/app-core/layout";
@import "./_includes/components/app-core/color-theme";

@import "./_includes/components/form/form";
@import "./_includes/components/app-footer/app-footer";
@import "./_includes/components/app-header/app-header";
@import "./_includes/components/mobile-navigation/mobile-navigation";
@import "./_includes/components/desktop-navigation/desktop-navigation";
@import "./_includes/components/quick-navigation/quick-navigation";
@import "./_includes/components/audio-player/audio-player";
@import "./_includes/components/video-player/video-player";
@import "./_includes/components/blocks/blocks";
@import "./_includes/components/bookmark/bookmark";
@import "./_includes/components/bookmark-count/bookmark-count";
@import "./_includes/components/card-primary/card-primary";
@import "./_includes/components/card-secondary/card-secondary";
@import "./_includes/components/card-tertiary/card-tertiary";
@import "./_includes/components/featured-articles/featured-articles";
@import "./_includes/components/header-banner/header-banner";
@import "./_includes/components/image-slider/image-slider";
@import "./_includes/components/install-prompt/install-prompt";
@import "./_includes/components/mirror-status/mirror-status";
@import "./_includes/components/post-image/post-image";
@import "./_includes/components/post-list/post-list";
@import "./_includes/components/post-quote/post-quote";
@import "./_includes/components/post-pullquote/post-pullquote";
@import "./_includes/components/post-button/post-button";
@import "./_includes/components/social-links/social-links";
@import "./_includes/components/social-share/social-share";
@import "./_includes/components/svg-icon/svg-icon";
@import "./_includes/components/svg-sprite/svg-sprite";
@import "./_includes/components/offscreen-message/offscreen-message";
@import "./_includes/components/news-timeline/news-timeline";
@import "./_includes/components/typography/typography";
@import "./_includes/components/newsletter-subscribe/newsletter-subscribe";
@import "./_includes/components/embed-handler/embed-handler";
@import "./_includes/components/embed-twitter/embed-twitter";
@import "./_includes/components/internal-banners/internal-banners";
@import "./_includes/components/offline-message/offline-message";
@import "./_includes/components/embed-youtube/embed-youtube";
@import "./_includes/components/embed-infogram/embed-infogram";
@import "./_includes/components/search-form/search-form";
@import "./_includes/components/grid-lines/grid-lines";
@import "./_includes/components/reading-time/reading-time";
@import "./_includes/components/card-meta/card-meta";
@import "./_includes/components/stale-content-notification/stale-content-notification";
@import "./_includes/components/featured-image/featured-image";
@import "./_includes/components/featured-video/featured-video";
@import "./_includes/components/post-inset/post-inset";
@import "./_includes/components/post-aside/post-aside";
@import "./_includes/components/section-primary/section-primary";
@import "./_includes/components/section-secondary/section-secondary";
@import "./_includes/components/section-header/section-header";
@import "./_includes/components/not-found/not-found";
@import "./_includes/components/promoted-slot/promoted-slot";
@import "./_includes/components/news-feed/news-feed";
@import "./_includes/components/section-tertiary/section-tertiary";
@import "./_includes/components/post-interview/post-interview";
@import "./_includes/components/section-filter/section-filter";
@import "./_includes/components/post-series/post-series";
@import "./_includes/components/page-header/page-header";
@import "./_includes/components/comment-list/comment-list";
@import "./_includes/components/mailto-form/mailto-form";
@import "./_includes/components/theme-menu/theme-menu";
@import "./_includes/components/settings-menu/settings-menu";
@import "./_includes/components/external-banners/external-banners";
@import "./_includes/components/daadkhast-banners/daadkhast-banners";
@import "./_includes/components/link-title/link-title";
@import "./_includes/components/font-size-menu/font-size-menu";
@import "./_includes/components/responsive-image/responsive-image";
@import "./_includes/components/table/table";
//@import "./_includes/components/post-timeline/post-timeline";
@import "./_includes/components/opinion-panel-intro/opinion-panel-intro";
@import "./_includes/components/highcharts/highcharts";
@import "./_includes/components/report-for-review/report-for-review";
@import "./_includes/components/inline-footnote/inline-footnote.scss";
@import "./_includes/components/push-subscribe/push-subscribe";
@import "./_includes/components/search-bar/search-bar";
@import "./_includes/components/search-results/search-results";
@import "./_includes/components/loader/loader";
@import "./_includes/components/audience-poll/audience-poll";
@import "./_includes/components/opinion-panel-form/opinion-panel-form";
@import "./_includes/components/opinion-panel-banner/opinion-panel-banner";
@import "./_includes/components/notification-center/notification-message";
@import "./_includes/components/modal/modal";
@import "./_includes/components/print-bookmarks/print-bookmarks";
@import "./_includes/components/share-instructions/share-instructions";
@import "./_includes/components/iran-after-dark/iran-after-dark";
@import "./_includes/components/newsletter-subscribe-notification/newsletter-subscribe-notification";
@import "./_includes/components/skip-link/skip-link";
@import "./_includes/components/tipjar/tipjar";
@import "./_includes/components/embed-aparat/embed-aparat";

@import "./pages/section/entry";
@import "./pages/posts/entry";
@import "./pages/bookmarks/index";
@import "./pages/splash/index";
@import "./pages/splash-b/index";
@import "./pages/search/index";
@import "./pages/censorship-circumvention-checklist/index";
@import "./pages/support-us/index";
