@use 'sass:math';
$breakpoint-social-share: 1350px;

.post__header {
  position: relative;
  z-index: 1;
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }

  &.post__header--collapsed {
    padding-bottom: $unit-xl;
  }
}

.post__header:not(.post__header--collapsed) .grid-lines {
  height: calc(100% - #{$card-meta-offset});
}

.post__header-content {
  @media all and (min-width: $breakpoint-800) {
    margin-bottom: $unit-xl;
  }
}

.post__header-preview-info {
  padding: $unit-s;
  text-align: center;
}

.post__header-content > * {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    width: math.div(100, 4) * 3%;
  }

  @media all and (min-width: $breakpoint-1200) {
    padding-left: 12.5%;
  }
}

.post__excerpt {
  margin-top: $unit-l;
}

.post__author-container {
  position: relative;
}

.post__author {
  margin-top: $unit-l;
  margin-bottom: $unit-s;
  display: flex;
  align-items: flex-end;

  @media all and (min-width: $breakpoint-800) {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;

    display: block;
  }
}

.post__author img {
  width: 60px;
  height: auto;
}

.post__author p {
  padding-right: $unit-s;
  padding-left: $unit-s;
  padding-bottom: $unit-xs;
  line-height: 1.5em;

  @media all and (min-width: $breakpoint-800) {
    padding: 0;
  }
}

.post__featured-media > * {
  width: calc(100% + #{$unit-s * 2});
  margin-right: -$unit-s;

  @media all and (min-width: $breakpoint-800) {
    width: math.div(100, 3) * 2%;
    margin-right: auto;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    width: math.div(100, 4) * 3%;
  }
}

.post__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;

  a[href*="//"]:not([href*="radiozamaneh.com"]):after,
  a[href*="//"]:not([href*="www.radiozamaneh.com"]):after {
    content: '\02196';
  }

  > * {
    order: 2;
  }
}

.post__content a:not([class]) {
  color: $color-blue;
  text-decoration: underline;

  &:hover {
    color: $color-brand-dark;
  }

  @include darkTheme() {
    color: $color-blue-shade-1;
    @include font-smoothing(true);

    &:hover {
      color: $color-blue-shade-2;
    }
  }
}

.post__content .grid-lines {
  top: -$card-meta-offset;
}

.post__social-share {
  pointer-events: none;

  @media all and (max-width: $breakpoint-social-share - 1px) {
    order: 10;
    width: 100%;
    max-width: $max-width-s;
    margin-right: auto;
    margin-left: auto;
  }

  @media all and (min-width: $breakpoint-social-share) {
    position: sticky;
    top: $unit-m;
    height: 0;
    overflow: visible;
    order: 2;
    margin-top: $unit-l;
    z-index: 5;
  }
}

.post__social-share-inner {
  pointer-events: auto;
  width: max-content;
  min-width: 250px;
  background-color: $color-brand-light-shade-2;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }

  @media all and (min-width: $breakpoint-social-share) {
    margin-right: auto;
    max-width: 250px;
  }
}

.post__info {
  position: relative;
  display: flex;
  margin-top: -$card-meta-offset;
}

.post__date {
  display: block;
  width: 50%;
  padding-right: $unit-s;
  padding-left: $unit-s;

  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }

  @media all and (min-width: $breakpoint-800) {
    width: math.div(100, 3) * 1.5%;
    margin-right: math.div(100, 3) * .5%;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    width: math.div(100, 4) * 1.5%;
    margin-right: math.div(100, 4) * .5%;
  }
}

.post__info-meta {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
  padding-right: $unit-s;

  color: $color-light;

  .post__header--collapsed ~ .post__content & {
    color: $color-dark;

    @include darkTheme() {
      color: $color-light;
    }
  }
}

.post__info-meta .bookmark:only-child {
  margin-right: auto;
}

.post__info-meta .reading-time {
  padding-top: $unit-xs;
}

.post__meta {
  margin-top: $unit-l;
}

.post__meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.post__meta-badge {
  margin-bottom: $unit-s;
  list-style-type: none;

  &:not(:last-child) {
    margin-left: $unit-s;
  }
}

.post__legacy-link {
  margin-top: $unit-m;
}
