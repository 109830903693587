.newsletter-subscribe {
  width: 100%;
  text-align: center;
}

.newsletter-subscribe .newsletter-subscribe__form-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.newsletter-subscribe__label {
  display: inline-block;
  margin-top: $unit-s;
}

.newsletter-subscribe__mail {
  max-width: 300px;
  border-color: transparent;
}

.newsletter-subscribe__submit {
  max-width: 100px;
  min-width: 100px;
  margin-right: $unit-xs;

  @media screen and (min-width: $breakpoint-600) {
    max-width: 120px;
    min-width: 120px;
    margin-right: $unit-s;
  }
}

.newsletter-subscribe .newsletter-subscribe__info {
  display: block;
}
