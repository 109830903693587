.audience-poll {
  display: none;

  .enhanced & {
    display: block;
  }
}

.audience-poll__inner {
  padding: $unit-l;
  background-color: $color-brand-light-shade-1;
  border-radius: $border-radius-small;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.audience-poll__answers {
  display: none;
  margin-top: $unit-m;

  &.is-visible {
    display: block;
  }
}

.audience-poll__answer {
  position: relative;

  & + & {
    margin-top: $unit-s;
  }
}

.audience-poll__answer input[type="radio"] {
  opacity: 0;
  position: absolute;
}

.audience-poll__answer-label {
  position: relative;
  display: block;
  max-width: 400px;
  padding: .2em 1em;

  background-color: $color-blue;
  color: $color-light;
  border-radius: $border-radius-small;

  cursor: pointer;
  transition: box-shadow .1s linear;
}

.audience-poll__answer-label:hover,
.audience-poll__answer input[type="radio"]:focus + .audience-poll__answer-label,
.audience-poll__answer input[type="radio"]:checked + .audience-poll__answer-label {
  background-color: $color-brand-dark;
}

.audience-poll__submit-button {
  margin-top: $unit-m;
}


.audience-poll__feedback-message {
  display: none;
  margin-top: $unit-m;

  &.is-visible {
    display: block;
  }
}
