.post-image {
  position: relative;
  margin-bottom: $unit-m;
  padding-right: $unit-m;
  padding-bottom: $unit-m;
  clear: both;

  background-color: $color-brand-dark-shade-2;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-2--darken;
  }

  @media all and (min-width: $breakpoint-600) {
    margin-bottom: $unit-l;
  }

  @media all and (min-width: $breakpoint-800) {
    margin-bottom: $unit-xl;
  }
}

.post-image--portait {
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  @media all and (min-width: $breakpoint-600) {
    width: 50%;
  }
}

.post-image figcaption {
  padding-top: $unit-s;
  padding-left: $unit-m;
}
