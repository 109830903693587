.post-inset {
  padding-bottom: $unit-s;
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }

  @media screen and (min-width: $breakpoint-1000) {
    padding-bottom: $unit-m;
  }
}

.post-inset--wide {
  padding-bottom: 0;

  @media screen and (min-width: $breakpoint-800) {
    display: flex;
    align-items: flex-start;
  }
}

.post-inset__unit {
  padding-top: $unit-s;
  padding-right: $unit-s;
  padding-left: $unit-s;

  @media screen and (min-width: $breakpoint-1000) {
    padding-right: $unit-m;
    padding-left: $unit-m;
  }
}

.post-inset__image {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;

  &:not(:first-child) {
    margin-top: $unit-s;
  }
}

.post-inset__caption {
  position: relative;
  z-index: 2;
  width: calc(100% - #{$unit-l});
  margin-top: -$card-meta-offset;

  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.post-inset__figure {
  width: 100%;

  @media screen and (min-width: $breakpoint-800) {
    width: 300px;
    min-width: 300px;
  }

  @media screen and (min-width: $breakpoint-1000) {
    width: 400px;
    min-width: 400px;
  }
}

.post-inset__content-wide {
  flex-grow: 2;
  padding: $unit-m;
}
