.splash-b__header {
  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }
}

.splash-b__logo {
  position: relative;
  display: block;
  max-width: 220px;
  aspect-ratio: 100 / 21.2;
  margin-right: auto;
  margin-left: auto;
  color: $color-light;
}

.splash-b__logo svg {
  position: absolute;
  top: 0;
  left: 0;
}

.splash-b__featured .bookmark {
  display: none;
}

.splash-b__banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-blue-shade-2;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }
}
