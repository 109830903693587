.section-secondary {
  position: relative;
  margin-top: $unit-l;

  @media screen and (min-width: $breakpoint-1000) {
    margin-top: $unit-xl;
  }
}

.section-secondary__content {
  position: relative;
  z-index: 1;
  padding-top: $unit-l;
  padding-bottom: $unit-l;

  @media screen and (min-width: $breakpoint-1200) {
    display: flex;
    align-items: flex-start;
    padding-top: $unit-xl;
    padding-bottom: $unit-xl;
  }
}

.section-secondary__featured {
  @media screen and (min-width: $breakpoint-600) {
    display: flex;

    > * {
      width: 50%;
    }
  }

  @media screen and (min-width: $breakpoint-1200) {
    width: 66.66%;
  }
}

.section-secondary__list {
  margin-top: $unit-m;

  @media screen and (min-width: $breakpoint-600) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (min-width: $breakpoint-1200) {
    display: block;
    width: 33.33%;
    margin-top: 0;
    padding-right: $unit-xl;
  }
}

.section-secondary__list > * {
  &:nth-child(n+2) {
    margin-top: $unit-m;
  }

  @media screen and (min-width: $breakpoint-600) {
    width: 50%;
    padding-left: $unit-m;

    &:nth-child(2) {
      margin-top: 0;
    }
    &:nth-child(n+3) {
      margin-top: $unit-m;
    }
  }

  @media screen and (min-width: $breakpoint-1200) {
    width: 100%;

    &:nth-child(n+2) {
      margin-top: $unit-m;
    }
  }
}

.section-secondary .grid-lines {
  z-index: 2;
}
