.card-primary {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.card-primary__header {
  order: 2;
  z-index: 2;

  display: flex;
  flex-direction: column;

  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }
}

.card-primary--video .card-primary__header {
  background-color: $color-dark-muted;

  @include darkTheme() {
    background-color: $color-dark-muted--darken;
  }
}

.card-primary--audio .card-primary__header {
  background-color: $color-yellow-shade-1;

  @include darkTheme() {
    background-color: $color-yellow-shade-2--darken;
  }
}

.card-primary--chat .card-primary__header,
.card-primary--quote .card-primary__header {
  background-color: $color-green-shade-1;

  @include darkTheme() {
    background-color: $color-green-shade-2--darken;
  }
}

.card-primary__header--stretch {
  flex-grow: 2;
}

.card-primary__link,
.card-primary__title {
  order: 2;
}

.card-primary .card-meta {
  order: 1;
}

.card-primary__title {
  font-size: 1.9rem;
  line-height: 1.5em;
  color: $color-brand-dark;
  font-variation-settings: "wght" 600, "SHAR" 0, "SERI" 0, "KASH" 5;
  transition: color .1s linear;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  @media screen and (min-width: $breakpoint-400) {
    font-size: 2.1rem;
  }

  @media screen and (min-width: $breakpoint-1200) {
    font-size: 2.4rem;
  }

  .card-primary:hover &,
  .card-primary:focus & {
    color: $color-dark;

    @include darkTheme() {
      color: $color-brand-light;
    }
  }
}

.card-primary--video .card-primary__title {
  color: $color-light;
}

.card-primary--audio .card-primary__title {
  color: $color-dark;
}

.card-primary .card-meta__info {
  color: $color-light;
}

.card-primary__excerpt {
  order: 3;
  flex-grow: 2;
  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }
}

.card-primary--video .card-primary__excerpt,
.card-primary--image .card-primary__excerpt,
.card-primary--gallery .card-primary__excerpt {
  background-color: $color-grey-medium;
}

.card-primary--audio .card-primary__excerpt {
  background-color: $color-yellow-shade-1;
}

.card-primary .card-primary__link {
  display: block;
  padding-top: $unit-m;
  padding-right: $unit-s;
  padding-bottom: $unit-s;
  padding-left: $unit-s;

  text-decoration: none;

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    padding-right: $unit-m;
    padding-bottom: $unit-l;
  }

  &.card-primary__excerpt {
    padding-top: 0;
  }
}

.card-primary--brand-light .card-primary__link {
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.card-primary__image-container {
  position: relative;
  order: 1;
  z-index: 1;
  display: block;
  width: calc(100% - #{$unit-m});
  margin-right: auto;
  padding-top: $card-img-ratio;
  overflow: hidden;
  background-color: $color-grey-medium;

  @include darkTheme() {
    background-color: rgba(black, .2);
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    width: calc(100% - #{$unit-l});
  }

  @media screen and (min-width: $breakpoint-1200) {
    width: calc(100% - #{$unit-xl});
  }
}

.card-primary__image-container:after {
  @include imageOverlay();
}

.card-primary__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;

  transition: transform .25s;
  will-change: transform;

  .card-primary:hover &,
  .card-primary:focus & {
    transform: scale(1.05);
  }
}

.card-primary__format-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  pointer-events: none;
  background-color: rgba(black, .5);
  color: $color-light;
}

.card-primary__format-icon .svg-icon {
  position: absolute;
  top: calc(50% - #{$icon-size});
  left: calc(50% - #{$icon-size});
  display: block;
  width: $icon-size * 2;
  height: $icon-size * 2;

  transform: scale(.7);
  transition: transform .15s;

  .card-primary:hover &,
  .card-primary:focus & {
    transform: scale(.9);
  }
}
