.inline-footnote__toggle {
  appearance: none;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: normal;

  cursor: pointer;

  &:hover {
    color: $color-blue;
  }

  @include darkTheme() {
    &:hover {
      color: $color-brand-light;
    }
  }
}

.inline-footnote__toggle::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  margin-right: .2em;
  margin-left: .2em;

  background-color: $color-brand-light-shade-2;
  border-radius: 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path stroke-width='5px' stroke='%23242424' fill='transparent' d='M30 12 L16 24 2 12'/></svg>");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;

  @include darkTheme() {
    background-color: $color-brand-light;
  }
}

.inline-footnote__toggle.toggle--active::after {
  transform: rotate(180deg);
}

.inline-footnote__content {
  display: table;
  padding: 1em;
  margin-bottom: .5em;
  background-color: $color-brand-light-shade-2;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.inline-footnote__content:not(.toggle--active) {
  display: none;
}
