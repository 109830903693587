@use 'sass:math';

.grid-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

.grid-lines__parent {
  position: relative;
}

.grid-lines__inner {
  position: relative;
  height: 100%;
}

.grid-lines__line {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: $color-border;

  @include darkTheme() {
    background-color: $color-border-light;
  }
}

// =====================================
//                             4 columns
// =====================================
.grid-lines--columns-4 .grid-lines__line  {
  &:nth-child(1) {
    right: 0;
  }

  &:nth-child(2) {
    right: 50%;
  }

  &:nth-child(3) {
    right: calc(100% - 1px);
  }

  &:nth-child(4),
  &:nth-child(5) {
    display: none;
  }

  @media screen and (min-width: $breakpoint-800) {
    &:nth-child(2) {
      right: 33.33%;
    }

    &:nth-child(3) {
      right: 66.66%;
    }

    &:nth-child(4) {
      display: block;
      right: calc(100% - 1px);
    }
  }

  @media screen and (min-width: $desktop-app-menu-breakpoint) {
    &:nth-child(2) {
      right: 25%;
    }

    &:nth-child(3) {
      right: 50%;
    }

    &:nth-child(4),
    &:nth-child(5) {
      display: block;
    }

    &:nth-child(4) {
      right: 75%;
    }

    &:nth-child(5) {
      left: 0;
    }
  }
}

// =====================================
//                             6 columns
// =====================================
.grid-lines--columns-6 .grid-lines__line  {
  &:nth-child(1) {
    right: 0;
  }

  &:nth-child(2) {
    right: 50%;
  }

  &:nth-child(3) {
    right: calc(100% - 1px);
  }

  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    display: none;
  }

  @media screen and (min-width: $breakpoint-600) {
    &:nth-child(2) {
      right: 25%;
    }

    &:nth-child(3) {
      right: 50%;
    }

    &:nth-child(4) {
      display: block;
      right: 75%;
    }

    &:nth-child(5) {
      display: block;
      right: calc(100% - 1px);
    }
  }

  @media screen and (min-width: $breakpoint-1200) {
    &:nth-child(2) {
      right: math.div(100, 6) * 1%;
    }

    &:nth-child(3) {
      right: math.div(100, 6) * 2%;
    }

    &:nth-child(4) {
      right: math.div(100, 6) * 3%;
    }

    &:nth-child(5) {
      right: math.div(100, 6) * 4%;
    }

    &:nth-child(6) {
      right: math.div(100, 6) * 5%;
    }

    &:nth-child(7) {
      right: calc(100% - 1px);
    }

    &:nth-child(6),
    &:nth-child(7) {
      display: block;
    }
  }
}

.grid-lines--fadeout .grid-lines__line {
  max-height: 50vh;
  background-color: transparent;
  background-image: linear-gradient(to bottom, rgba($color-brand-dark, 0.1), rgba($color-brand-dark, 0));
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;

  @include darkTheme() {
    background-color: transparent;
    background-image: linear-gradient(to bottom, rgba($color-brand-light, 0.1), rgba($color-brand-light, 0));
  }
}
