// ============================= header
.share-instructions__header {
  padding: $unit-xs $unit-s;
}

.share-instructions__toggle {
  display: block;
  line-height: 1.1em;
}

.share-instructions__toggle .svg-icon {
  display: inline-block;
  width: .8em;
  height: .8em;
  margin-left: .2em;

  .toggle--active & {
    transform: rotate(180deg);
  }
}

.share-instructions__toggle--close {
  display: none;
}

#share-instructions.toggle--active,
#share-instructions:target {
  .share-instructions__toggle--close {
    display: block;
  }

  .share-instructions__toggle--open {
    display: none;
  }
}

  // ============================= content
.share-instructions__content {
  display: none;
  padding-right: $unit-s;
  padding-left: $unit-s;
  
  #share-instructions:target &,
  .toggle--active & {
    display: block;
  }
}

// tabs navigation
.share-instructions__tablist {
  display: flex;
  justify-content: space-between;
}

.share-instructions__tab-button {
  width: calc(50% - #{$unit-xs});
  padding: $unit-s $unit-xs;
  border: none;
  cursor: pointer;

  line-height: 1em;
  color: $color-dark;

  background-color: $color-brand-light-shade-1;
  color: $color-dark;
  border-radius: 0;
  transition: background-color .1s linear;

  &:hover {
    background-color: $color-brand-light;
  }

  &.tab--active {
    box-shadow: inset 0 0 0 3px $color-blue--darken;
  }
}

.share-instructions__tab-button img {
  display: block;
  width: 45px;
  height: auto;
  margin-top: $unit-s;
  margin-right: auto;
  margin-left: auto;
}

.share-instructions__platform-label {
  font-variation-settings: "wght" 400, "wdth" 0, "slnt" 0;
}

// tabs content
.share-instructions__option {
  display: none;

  &.panel--active {
    display: block;
  }
}

#share-instruction-android:target .share-instructions__option--android,
#share-instruction-ios:target .share-instructions__option--ios {
  display: block;
}

.share-instructions__list {
  margin-top: $unit-m;
  counter-reset: list-counter;
}

.share-instructions__list li {
  position: relative;
  padding-right: 1.2em;
  counter-increment: list-counter;
  line-height: 1.5em;
}

.share-instructions__list li + li {
  margin-top: .3em;
}

.share-instructions__list li:before {
  position: absolute;
  top: 0;
  right: 0;
  content: counter(list-counter, arabic-indic) '\002e';
}

.share-instructions__option-button {
  margin-top: $unit-s;
}

.share-instructions__manual-link {
  display: inline-flex;
  align-items: center;
  margin-top: $unit-s;

  line-height: 1.2em;

  &:hover {
    text-decoration: underline;
  }
}

.share-instructions__manual-link .svg-icon {
  display: block;
  width: 1em;
  height: 1em;
  margin-left: .1em;

  transform: rotate(90deg);
}
