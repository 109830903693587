.app-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: $unit-xl;
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }

  @media all and (min-width: $breakpoint-1000) {
    margin-top: $unit-xxl;
  }

  // space for the mobile quicknav
  @media all and (max-width: $desktop-app-menu-breakpoint - 1) {
    margin-bottom: $quick-nav-height;
  }
}

.app-footer__bottom {
  width: 100%;
  padding-bottom: $unit-m;
  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }

  @media all and (min-width: $breakpoint-1000) {
    padding-right: $unit-l;
    padding-bottom: $unit-l;
    padding-left: $unit-l;
  }
}

.app-footer__bottom-columns {
  @media all and (min-width: $breakpoint-1000) {
    display: flex;
    padding-top: $unit-m;
  }
}

.app-footer__bottom-unit {
  padding-top: $unit-m;
  padding-bottom: $unit-m;

  @media all and (max-width: $breakpoint-1000 - 1px) {
    padding-right: $unit-m;
    padding-left: $unit-m;
    border-top: 1px solid rgba($color-light, 0.1);
  }

  @media all and (min-width: $breakpoint-1000) {
    width: 30%;
    display: flex;
    justify-content: center;

    &:nth-child(1) {
      padding-left: $unit-l;
    }

    &:nth-child(2) {
      width: 40%;
      margin-bottom: -1.2rem;
    }

    &:nth-child(3) {
      padding-right: $unit-l;
    }
  }
}

.app-footer__copyright {
  line-height: 1.2em;
}

.app-footer__bottom-unit > * {
  @media all and (min-width: $breakpoint-1000) {
    width: 100%;
  }
}

.app-footer__logo {
  display: block;
  width: 220px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  color: $color-light;

  @media all and (min-width: $breakpoint-800) {
    width: 250px;
    height: 52px;
  }
}
.app-footer__logo img {
  max-height: 100%;
}

.app-footer__legal-links {
  margin-top: $unit-m;
}

.app-footer__legal-links li {
  display: inline-block;
}

.app-footer__legal-links a {
  @include darkTheme() {
    color: $color-light;
  }

  &:hover {
    text-decoration: none;
    color: $color-blue-shade-1;

    @include darkTheme() {
      color: #cadbff;
    }
  }
}

.app-footer__external-links-title {
  @media all and (min-width: $breakpoint-1000) {
    text-align: right;
  }
}

.app-footer__external-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (min-width: $breakpoint-600) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.app-footer__external-links li {
  margin-top: $unit-xs;
  max-width: 300px;
  width: 100%;
  padding-right: $unit-xs * .5;
  padding-left: $unit-xs * .5;

  > * {
    width: 100%;
  }

  @media all and (min-width: $breakpoint-600) {
    flex-basis: 33.33%;
    max-width: none;
  }

  @media all and (min-width: $breakpoint-1000) {
    flex-basis: 100%;
  }
}
