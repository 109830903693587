.bookmark-count {
  background-color: $color-brand-dark;
  border-radius: 1rem;
  color: $color-light;
  display: inline-block;
  line-height: 1;
  min-width: 2rem;
  padding: 0.3rem 0.6rem;
  position: absolute;
  left: 50%;
  text-align: center;
  top: -0.6rem;
  vertical-align: middle;
  white-space: nowrap;

  &:empty {
    display: none;
  }

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }
}
