.highcharts {
  display: flex;
  flex-direction: column;

  > *:not(.table--hidden) {
    width: 100%;
  }
}

.highcharts__chart {
  order: 1;
  direction: ltr;

  border: 1px solid $color-border;
  border-bottom: 0;

  @include darkTheme() {
    border-color: $color-border-light;
  }

  .highcharts__caption + & {
    margin-top: $unit-s;
  }
}

.highcharts__chart--heatmap {
  padding-bottom: $unit-m;
  border-bottom: 1px solid $color-border;
}

.highcharts-root {
  display: block;
  width: 100%;
  height: 100%;
  direction: ltr;
}

.highcharts-text-outline {
  stroke-width: 0 !important;
}

.highcharts-grid {
  @include darkTheme() {
    opacity: .5;
  }
}

.highcharts text {
  @include darkTheme() {
    fill: white !important;
  }
}

.highcharts .table {
  order: 3;
}


body:not(.enhanced) .highcharts .table:target,
body:not(.enhanced) .highcharts .table.table--hidden:target {
  display: block;
}

.highcharts__actions {
  order: 2;

  border: 1px solid $color-border;
  border-top: 0;

  @include darkTheme() {
    border-color: $color-border-light;
  }
}

.highcharts .table.toggle--active ~ .highcharts__actions {
  margin-top: 0;
}

.highcharts__toggle {
  display: flex;
  align-items: center;
}

.highcharts__toggle-close {
  display: none;
}

.table.toggle--active ~ .highcharts__actions .highcharts__toggle-open,
body:not(.enhanced) .table:target ~ .highcharts__actions .highcharts__toggle-open {
  display: none;
}

.table.toggle--active ~ .highcharts__actions .highcharts__toggle-close,
body:not(.enhanced) .table:target ~ .highcharts__actions .highcharts__toggle-close {
  display: flex;
}

.highcharts__toggle .svg-icon {
  display: block;
  width: $icon-size-small;
  height: $icon-size-small;
  margin-left: $unit-s;
}

.highcharts__toggle-close .svg-icon {
  transform: rotate(180deg);
}
