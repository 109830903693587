.card-tertiary {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.card-tertiary__link {
  display: flex;
  flex-direction: column;
}

.card-tertiary__title {
  order: 2;

  font-size: 1.9rem;
  line-height: 1.5em;
  color: $color-brand-dark;
  font-variation-settings: "wght" 600, "SHAR" 0, "SERI" 0, "KASH" 5;
  transition: color .1s linear;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  @media screen and (min-width: $breakpoint-400) {
    font-size: 2.1rem;
  }

  @media screen and (min-width: $breakpoint-1200) {
    font-size: 2.4rem;
  }

  .card-tertiary:hover &,
  .card-tertiary:focus & {
    color: $color-dark;

    @include darkTheme() {
      color: $color-brand-light;
    }
  }
}

.card-tertiary__meta {
  order: 1;
  margin-bottom: $unit-s;
}
