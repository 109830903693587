$splash-news-small:  250px;
$splash-news-medium:  350px;
$splash-news-large:  25%;
$splash-offset: 100px;

.splash__container {
  display: flex;
  flex-direction: column;

  @media all and (min-height: 650px) {
    min-height: 100vh;
  }
}

.splash__header {
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }
}

.splash__logo {
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
  color: $color-brand-dark;

  @include darkTheme() {
    color: $color-light;
  }
}

.splash__logo .svg-icon {
  display: block;
  width: 220px;
  height: 45px;
  margin-right: auto;
  margin-left: auto;
}

.splash__header-links {
  max-width: 400px;
  margin-top: $unit-s;
  margin-right: auto;
  margin-left: auto;

  @media all and (min-width: 380px) {
    display: flex;
    justify-content: space-between;
  }

  @media all and (min-width: $breakpoint-800) {
    max-width: 500px;
    margin-top: $unit-m;
  }
}

.splash__header-links li {

  @media all and (max-width: 379px) {
    display: block;
    width: 100%;

    &:not(:first-child) {
      margin-top: $unit-xs;
    }
  }

  @media all and (min-width: 380px) {
    flex-basis: calc(50% - #{$unit-s});
  }
}

.splash__header-links a {
  display: block;
  width: 100%;
}

.splash__external-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $unit-xs;

  @media all and (min-width: $breakpoint-800) {
    margin-top: $unit-s;
  }
}

.splash__external-links li {
  width: calc(50% - #{$unit-l});
  max-width: 100px;
  margin-right: $unit-m;
  margin-left: $unit-m;

  @media all and (min-width: $breakpoint-1000) {
    max-width: 150px;
  }
}

.splash__ad-slot .promoted-slot__container {
  height: 50px;
}

.splash__news {
  position: relative;
  padding-top: $unit-l;
  padding-bottom: $unit-l;
  flex-grow: 2;

  background-color: $color-brand-dark-shade-2;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }

  @media all and (min-width: $breakpoint-1200) {
    padding-right: $unit-l;
    padding-left: $unit-l;
  }
}

.splash__news-container {
  overflow: hidden;
}

.splash__news:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100px;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(to right, $color-brand-dark-shade-2, rgba($color-brand-dark-shade-2, 0));
  background-size: cover;

  @include darkTheme() {
    background-image: linear-gradient(to right, $color-brand-dark-shade-1--darken, rgba($color-brand-dark-shade-1--darken, 0));
  }

  @media all and (min-width: $breakpoint-1200) {
    display: none;
  }
}

.splash__news-inner {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: -$splash-offset;
  padding-bottom: $splash-offset;
}

.splash__news-list {
  position: relative;
  z-index: 1;
  display: flex;
}

.splash__news-item {
  width: $splash-news-small;
  min-width: $splash-news-small;

  @media all and (min-width: $breakpoint-400) {
    width: $splash-news-medium;
    min-width: $splash-news-medium;
  }

  @media all and (min-width: $breakpoint-1200) {
    width: $splash-news-large;
    min-width: $splash-news-large;
  }
}
