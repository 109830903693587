$input-border-color: $color-dark-muted;
$input-border-color-focus: $color-dark;

input[type="email"] {
  direction: ltr;
  text-align: left;
}

input[type="number"] {
  @include fontBase;
  direction: ltr;
  text-align: left;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form__unit {
  display: flex;
  flex-direction: column;

  &:not(:first-child):not(:empty) {
    margin-top: $unit-s;
  }

  @media all and (min-width: $breakpoint-800) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.form__indented {
  width: 100%;

  &:not(:first-child):not(:empty) {
    margin-top: $unit-s;
  }

  @media all and (min-width: $breakpoint-800) {
    max-width: calc(100% - 15rem - #{$unit-l});
    margin-right: auto;
  }
}

[form-required] {
  position: relative;
  margin-top: $unit-m + $unit-s !important;

  &:before {
    content: attr(form-required);
    position: absolute;
    bottom: 100%;
    right: 0;

    @include fontAccent;
    font-size: 1.6rem;
    line-height: 1.2em;
    font-variation-settings: "wght" 300, "wdth" 75, "slnt" 0;
    color: $color-dark-muted;
  }
}

// ====================================== text input
.form__text-input-label {
  margin-right: $unit-s;

  @media all and (min-width: $breakpoint-800) {
    margin-left: $unit-l;
    margin-right: 0;
    min-width: 15rem;
    max-width: 15rem;
  }
}

.form__text-input {
  display: block;
  width: 100%;

  padding: $unit-xs * 1.3 $unit-s $unit-xs $unit-s;

  font-size: inherit;
  font-family: inherit;
  border-width: $border-thin;
  border-style: solid;
  border-color: $input-border-color;
  outline: none;
  transition: color .2s, border-color .2s;

  @include darkTheme() {
    background-color: transparent;
    border-color: $color-brand-light;
    color: $color-light;
    @include font-smoothing(true);
  }

  @media all and (min-width: $breakpoint-800) {
    max-width: calc(100% - 15rem - #{$unit-l});
  }

  &::placeholder {
    opacity: 0.55;
    color: currentColor;
  }

  &:focus {
    border-color: $input-border-color-focus;

    @include darkTheme() {
      border-color: $color-light;
    }
  }

  .form--is-validated &:invalid {
    border-color: $color-red;
  }

  .form--is-validated &:valid {
    border-color: $color-green;
  }
}

// ====================================== checkbox
.form__checkbox-wrapper {
  position: relative;
  padding-right: $checkbox-size + $unit-s;
}

.form__checkbox,
.form__fake-checkbox {
  position: absolute;
  top: 0;
  right: 0;
  width: $checkbox-size;
  height: $checkbox-size;
}

.form__checkbox {
  opacity: 0;
  cursor: pointer;
}

.form__checkbox:focus-visible + .form__fake-checkbox {
  box-shadow: inset 0 0 0 2px $color-dark;
}

.form__fake-checkbox {
  pointer-events: none;
  box-shadow: inset 0 0 0 2px $input-border-color;
  border-radius: $border-radius-small;

  .form--is-validated .form__checkbox:invalid ~ & {
    box-shadow: inset 0 0 0 2px $color-red;
  }

  .form--is-validated .form__checkbox:valid ~ & {
    box-shadow: inset 0 0 0 2px $color-green;
  }
}

.form__fake-checkbox:before {
  content: '';
  display: none;
  top: 0;
  left: 0;
  width: $checkbox-size * 1.1;
  height: $checkbox-size * .6;

  border-bottom: 4px solid $color-green;
  border-left: 4px solid $color-green;
  transform: rotate(-45deg) translateX(20%);
}

.form__checkbox:checked ~ .form__fake-checkbox:before {
  display: block;
}

// ====================================== submit
.form__submit {
  margin-top: $unit-m;

  @media all and (min-width: $breakpoint-800) {
    margin-right: calc(15rem + #{$unit-l});
  }
}

// ====================================== error
.form__error-message {
  display: none;

  margin-top: $unit-s;
  color: $color-red;

  .form--is-validated:invalid & {
    display: block;
  }
}

[data-form] {
  display: none;

  &.is-enhanced {
    display: block;
  }
}

[data-form-feedback] {
  margin-bottom: $unit-m;

  &:empty {
    display: none;
  }
}

[data-form-feedback="success"] {
  background-color: $color-green-shade-2;
  @include darkTheme {
    background-color: $color-green--darken;
  }
}

[data-form-feedback="error"] {
  background-color: $color-red-shade-2;
  @include darkTheme {
    background-color: $color-red--darken;
  }
}
