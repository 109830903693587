@import "src/_includes/components/app-header/app-header.variables";

// section only provides padding options
.section {
  padding-right: $unit-s;
  padding-left: $unit-s;

  @media screen and (min-width: $breakpoint-800) {
    padding-right: $unit-l;
    padding-left: $unit-l;
  }
}

.section--escape-padding {
  position: relative;
  right: -$unit-s;
  width: calc(100% + #{$unit-s * 2});

  @media screen and (min-width: $breakpoint-800) {
    right: -$unit-l;
    width: calc(100% + #{$unit-l * 2});
  }
}

.section--spaced {
  padding-top: $unit-s;
  padding-bottom: $unit-s;

  @media screen and (min-width: $breakpoint-800) {
    padding-top: $unit-l;
    padding-bottom: $unit-l;
  }
}

.section--spaced-wide {
  padding-top: $unit-l;
  padding-bottom: $unit-l;

  @media screen and (min-width: $breakpoint-800) {
    padding-top: $unit-xl;
    padding-bottom: $unit-xl;
  }
}

.section--top-spaced {
  padding-top: $unit-m;

  @media all and (min-width: $breakpoint-600) {
    padding-top: $unit-l;
  }

  @media all and (min-width: $breakpoint-800) {
    padding-top: $unit-xl;
  }

  @media all and (min-width: $breakpoint-1000) {
    padding-top: $unit-xxl;
  }
}

// section color options
.section--theme-default {
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.section--theme-dark {
  background-color: $color-brand-dark-shade-1;

  @include darkTheme() {
    background-color: $color-brand-dark-shade-1--darken;
  }
}

.section--theme-light {
  background-color: $color-brand-light-shade-2;

  @include darkTheme() {
    background-color: $color-brand-light-shade-2--darken;
  }
}

.section--theme-yellow {
  background-color: $color-yellow-shade-2;

  @include darkTheme() {
    background-color: $color-yellow-shade-2--darken;
  }
}

.section--theme-blue {
  background-color: $color-blue-shade-2;

  @include darkTheme() {
    background-color: $color-blue-shade-2--darken;
  }
}

.section--theme-grey {
  background-color: $color-dark;
}


// container for horizontal centering
.container {
  max-width: $max-width-xl;
  margin-right: auto;
  margin-left: auto;
}

.container--width-large {
  max-width: $max-width-l;
}

.container--width-medium {
  max-width: $max-width-m;
}

.container--width-small {
  max-width: $max-width-s;
}

.container--width-xsmall {
  max-width: $max-width-xs;
}

// columns 2
.columns-2 {
  @media all and (max-width: ($breakpoint-1000 - 1)) {
    > *:not(:first-child) {
      margin-top: $unit-l;
    }
  }

  @media all and (min-width: $breakpoint-1000) {
    display: flex;
    flex-wrap: wrap;

     > * {
       width: 50%;
     }

     > *:nth-child(n+3) {
      margin-top: $unit-l;
    }
  }
}

// ====================================== columns 3
.columns-3 {
  @media all and (min-width: $breakpoint-600) {
    display: flex;
    flex-wrap: wrap;

     > * {
       width: 50%;
     }
  }

  @media all and (min-width: $breakpoint-800) {
    > * {
      width: 33.33%;
    }
  }
}

.columns-3 > * {
  @media all and (max-width: ($breakpoint-800 - 1)) {
    &:not(:last-child) {
      margin-bottom: $unit-m;
    }
  }

  @media all and (min-width: $breakpoint-800) {
    margin-bottom: $unit-m;
  }

  @media all and (min-width: $desktop-app-menu-breakpoint) {
    margin-bottom: $unit-l;
  }

  @media all and (min-width: $breakpoint-1200) {
    margin-bottom: $unit-xl;
  }
}

// ====================================== columns 4
.columns-4 {
  @media all and (min-width: $breakpoint-600) {
    display: flex;
    flex-wrap: wrap;
  }
}

.columns-4 > * {
  @media all and (max-width: ($breakpoint-600 - 1)) {
    &:not(:last-child) {
      margin-bottom: $unit-m;
    }
  }

  @media all and (min-width: $breakpoint-600) {
    margin-bottom: $unit-m;
  }

  @media (min-width: $breakpoint-600) and (max-width: ($breakpoint-1000 - 1)) {
    width: 50%;
  }

  @media all and (min-width: $breakpoint-1000) {
    width: 25%;
  }
}

.margin-top {
  margin-top: 10px;

  @media (min-width: $breakpoint-600) {
    margin-top: 20px;
  }
}

.margin-top-large {
  margin-top: 20px;

  @media (min-width: $breakpoint-600) {
    margin-top: 40px;
  }
}
