.offscreen-message {
  position: fixed;
  z-index: 100;
  padding: $unit-s $unit-m;
  border-radius: 5px;
  box-shadow: 0 20px 30px 0 rgba($color-dark, 0.3);

  transition: opacity .25s ease-in, transform .25s ease-in;
  opacity: 0;
  pointer-events: none;
}

.offscreen-message--toast {
  bottom: $unit-s;
  left: 50%;
  width: calc(100% - (#{$unit-m} * 2));
  max-width: 400px;

  background-color: $color-dark;
  color: $color-light;
  text-align: center;
  line-height: 1.5;

  transform: translate(-50%, 100%);
}

.offscreen-message--notification {
  right: $unit-s;
  width: calc(100% - (#{$unit-s} * 2));
  max-width: 600px;

  background-color: $color-light;
  color: $color-dark;

  @media screen and (max-width: ($breakpoint-600 - 1px)) {
    bottom: $quick-nav-height + $unit-s;
    transform: translateY( calc(#{$quick-nav-height + $unit-s} + 100% ));
  }

  @media screen and (min-width: $breakpoint-600) {
    top: $unit-s;
  }
}

.offscreen-message--is-active {
  transition: opacity .25s ease-out, transform .25s ease-out;
  opacity: 1;
  pointer-events: auto;
}

.offscreen-message--is-active.offscreen-message--toast {
  transform: translate(-50%, 0%);
}

.offscreen-message--is-active.offscreen-message--notification {
  transform: translateY(0%);
}
