.embed-youtube__media {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; // ratio 16:9

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
