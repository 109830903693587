.iran-after-dark__status {
  display: inline-block;

  svg {
    width: 20px;
    height: 20px;
  }
}

[data-iran-after-dark-status="available"] {
  color: $color-mirror-up;
}

[data-iran-after-dark-status="unavailable"] {
  color: $color-mirror-low;
}

