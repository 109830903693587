$timeline-item-count: 10;
$timeline-item-width-mobile: 300px;
$timeline-item-width-desktop: 500px;
$timeline-offset: 100px;

.news-timeline {
  position: relative;
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.news-timeline__title {
  margin-bottom: $unit-l;

  &:not(:first-child) {
    margin-top: $unit-l;
  }
}

.news-timeline__content {
  position: relative;
  overflow: hidden;
}

.news-timeline__content:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  min-width: 100px;
  height: 100%;

  pointer-events: none;
  background-image: linear-gradient(to right, $color-brand-light-shade-1, rgba($color-brand-light-shade-1, 0));
  background-size: cover;

  @include darkTheme() {
    background-image: linear-gradient(to right, $color-brand-light-shade-1--darken, rgba($color-brand-light-shade-1--darken, 0));
  }

  @media screen and (min-width: $max-width-xl) {
    width: calc((100% - #{$max-width-xl}) * .5);
  }
}

.news-timeline__inner {
  margin-bottom: -$timeline-offset;
  padding-right: $unit-s;
  padding-bottom: $timeline-offset;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: $breakpoint-800) {
    padding-right: $unit-l;
  }

  @media screen and (min-width: $max-width-xl) {
    padding-right: calc((100% - #{$max-width-xl}) * .5);
  }
}

.news-timeline__items {
  min-width: $timeline-item-count * $timeline-item-width-mobile + $unit-m;
  display: flex;

  @media screen and (min-width: $breakpoint-800) {
    min-width: $timeline-item-count * $timeline-item-width-desktop + $unit-l;
  }
}

.news-timeline__item {
  position: relative;
  width: $timeline-item-width-mobile;
  flex-shrink: 0;

  // enable child to fill 100% height
  display: flex;
  flex-direction: column;

  box-shadow: inset -2px 0 0 $color-light;

  @include darkTheme() {
    box-shadow: inset -2px 0 0 $color-dark;
  }

  @media screen and (min-width: $breakpoint-800) {
    width: $timeline-item-width-desktop;
  }
}

.news-timeline__anchor {
  width: 100%;
  flex-grow: 2;

  // flex-box to define visual order
  // and push .top-news__timeline-date to the right
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-right: $unit-s;
  padding-left: $unit-s;

  transition: background-color 0.15s linear;

  @media screen and (min-width: $breakpoint-800) {
    padding-right: $unit-l;
  }

  &:hover,
  &:focus {
    background-color: rgba($color-light, 0.3);

    @include darkTheme() {
      background-color: rgba($color-dark, 0.3);
    }
  }
}

.news-timeline__item-title {
  order: 2;
  width: 100%;

  font-size: 1.9rem;
  line-height: 1.5em;
  color: $color-brand-dark;
  font-variation-settings: "wght" 600, "SHAR" 0, "SERI" 0, "KASH" 5;

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing(true);
  }

  @media screen and (min-width: $breakpoint-400) {
    font-size: 2.1rem;
  }

  @media screen and (min-width: $breakpoint-1200) {
    font-size: 2.4rem;
  }
}

.news-timeline__item-date {
  position: relative;
  right: -$unit-s;
  order: 1;
  margin-bottom: $unit-s;
  padding: $unit-s $unit-s $unit-xs $unit-s;

  line-height: 1.1em;
  background-color: $color-light;

  @include darkTheme() {
    background-color: $color-dark;
  }

  @media screen and (min-width: $breakpoint-800) {
    right: -$unit-l;
    margin-bottom: $unit-m;
    padding: $unit-s $unit-l $unit-xs $unit-l;
  }
}

.news-timeline__item-excerpt {
  order: 3;
  width: 100%;

  @media screen and (max-width: $breakpoint-800 - 1px) {
    display: none;
  }
}
