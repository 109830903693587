@import "src/_includes/components/app-header/app-header.variables";
$tooltip-pointer-size: 16px;

.mirror-status {
  width: $quick-nav-height;
  height: $quick-nav-height;

  display: none;
}

// mobile
.quick-navigation .mirror-status {
  @media all and (max-width: #{$desktop-app-menu-breakpoint - 1}) {
    position: relative;

    &.mirror-status--enhanced {
      display: block;
    }
  }
}

// desktop
.app-header > .mirror-status {
  @media all and (min-width: #{$desktop-app-menu-breakpoint}) {
    position: fixed;
    bottom: $unit-m;
    left: $unit-m;
    z-index: 50;

    &.mirror-status--enhanced {
      display: block;
    }
  }
}

.mirror-status .button--icon {
  width: $quick-nav-height;
  height: $quick-nav-height;
  min-width: $quick-nav-height;
  min-height: $quick-nav-height;
  transition: box-shadow .15s;

  .svg-icon {
    position: absolute;
    top: calc(50% - #{$icon-size * .5});
    left: calc(50% - #{$icon-size * .5});
    width: $icon-size;
    height: $icon-size;
  }
}

.mirror-status__message {
  display: none;
}

[class*="mirror-status__icon--"],
[class*="mirror-status__message--"] {
  display: none;
}

[class*="mirror-status__icon--"] {
  position: relative;
}

[data-mirror-status] {
  .button {
    color: $color-light;
  }

  .button--icon {
    background-color: $color-dark-muted;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 4px $color-dark-muted;
    }
  }

  &:not([data-mirror-status="up"]):not([data-mirror-status="down"]):not([data-mirror-status="low"]) .mirror-status__icon--unknown {
    display: block;
  }

  &:not([data-mirror-status="up"]):not([data-mirror-status="down"]):not([data-mirror-status="low"]) .mirror-status__message--unknown {
    display: block;
  }
}

[data-mirror-status="up"] {
  .button--icon {
    background-color: $color-mirror-up;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 4px $color-mirror-up;
    }
  }

  .mirror-status__icon--up,
  .mirror-status__message--up {
    display: block;
  }
}

[data-mirror-status="low"] {
  .button--icon {
    background-color: $color-mirror-low;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 4px $color-mirror-low;
    }
  }

  .mirror-status__icon--low,
  .mirror-status__message--low {
    display: block;
  }
}

[data-mirror-status="down"] {
  .button--icon {
    background-color: $color-mirror-down;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 4px $color-mirror-down;
    }
  }

  .mirror-status__icon--down,
  .mirror-status__message--down {
    display: block;
  }
}

.mirror-status__icon--enabled,
.mirror-status__icon--disabled {
  position: relative;

  .svg-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.mirror-status__tooltip {
  display: none;

  .mirror-status.toggle--active & {
    display: block;
  }

  position: absolute;
  bottom: calc(100% + #{$unit-m});
  left: 0;

  width: 200px;
  padding: $unit-s;

  background-color: $color-light;
  box-shadow: 0 0 15px rgba(black, 0.2);

  @include darkTheme() {
    background-color: $color-dark;
  }
}

@supports (filter: drop-shadow(0 0 0)) {
  .mirror-status__tooltip {
    box-shadow: none;
    filter: drop-shadow(0 0 20px rgba(black, 0.2))
  }
}

.mirror-status__tooltip:before {
  content: '';
  position: absolute;
  bottom: $tooltip-pointer-size * -0.5;
  left: $icon-size * 0.5 - $tooltip-pointer-size * 0.5;
  width: $tooltip-pointer-size;
  height: $tooltip-pointer-size;
  background-color: $color-light;

  transform: rotate(45deg);

  @include darkTheme() {
    background-color: $color-dark;
  }
}
