$desktop-navigation-height: 55px;

.desktop-navigation {
  position: relative;
  display: block;
  width: 100%;
  height: $desktop-navigation-height;

  background-color: $color-brand-dark;
  background-image: linear-gradient(to right, $color-brand-dark 50%, $color-brand-light-shade-1 50%);
  background-size: cover;
  box-shadow: 0 1px 0 $color-border;

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
    background-image: linear-gradient(to right, $color-brand-dark--darken 50%, $color-brand-light-shade-1--darken 50%);
    box-shadow: 0 1px 0 $color-border-light;
  }

  @media all and (max-width: $desktop-app-menu-breakpoint - 1px) {
    display: none;
  }
}

.desktop-navigation__inner {
  display: flex;
  width: 100%;
}

.desktop-navigation__items {
  display: flex;
  width: 100%;
  background-color: $color-brand-light-shade-1;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
  }
}

.desktop-navigation__item-link {
  display: flex;
  align-items: center;
  height: $desktop-navigation-height;
  padding-right: $unit-s;

  color: $color-brand-dark;

  &:hover {
    background-color: $color-border;
  }

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing();

    &:hover {
      background-color: $color-border-light;
    }
  }
}

.desktop-navigation__item-link span {
  position: relative;
  width: $icon-size-small + $unit-s;
  height: $desktop-navigation-height;
}

.desktop-navigation__item-link .svg-icon {
  position: absolute;
  top: calc(50% - #{$icon-size-small * .5});
  left: calc(50% - #{$icon-size-small * .5});
  width: $icon-size-small;
  height: $icon-size-small;
}

.desktop-navigation__dropdown {
  display: none;

  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $color-brand-light-shade-1;
  box-shadow: 0 20px 20px -10px rgba(black, 0.15);
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;

  @include darkTheme() {
    background-color: $color-brand-light-shade-1--darken;
    border-top: 1px solid $color-border-light;
    border-bottom: 1px solid $color-border-light;
  }

  &.toggle--active,
  &:target {
    display: block;
  }
}

.desktop-navigation__columns {
  position: relative;
  width: 100%;
  display: flex;
}

.desktop-navigation__subitems {
  padding-top: $unit-m;
  padding-bottom: $unit-m;

  border-right: 1px solid $color-border;

  @include darkTheme() {
    border-right: 1px solid $color-border-light;
  }
}

.desktop-navigation__subitem-link {
  display: block;
  padding-right: $unit-s;
  padding-left: $unit-s;
  color: $color-brand-dark;

  &:hover {
    background-color: $color-border;
  }

  @include darkTheme() {
    color: $color-light;
    @include font-smoothing();

    &:hover {
      background-color: $color-border-light;
    }
  }
}

.desktop-navigation__close-fallback {
  position: absolute;
  top: 0;
  left: 0;
  height: $desktop-navigation-height;

  transform: translateX(100%) translateY(-100%);
  color: transparent;

  .enhanced & {
    display: none;
  }
}

.desktop-navigation__actions {
  position: relative;
  z-index: 10;
  display: flex;
  background-color: $color-brand-dark;

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }
}

.desktop-navigation__search {
  position: relative;
  z-index: 2;
}

.desktop-navigation__search-toggle-open {
  display: block;

  .desktop-navigation__search.toggle--active &,
  .desktop-navigation__search:target & {
    display: none;
  }
}

.desktop-navigation__search-toggle-close {
  display: none;

  .desktop-navigation__search.toggle--active &,
  .desktop-navigation__search:target & {
    display: block;
  }
}

.desktop-navigation__search-toggle-open,
.desktop-navigation__search-toggle-close {
  position: relative;
  width: $desktop-navigation-height;
  height: $desktop-navigation-height;
  color: $color-light;
}

.desktop-navigation__search-toggle-open .svg-icon,
.desktop-navigation__search-toggle-close .svg-icon {
  position: absolute;
  top: calc(50% - #{$icon-size * .5});
  left: calc(50% - #{$icon-size * .5});
  width: $icon-size;
  height: $icon-size;
  transform: scale(.6);
}

.desktop-navigation__search-wrapper {
  display: none;

  position: absolute;
  top: 0;
  left: 100%;
  width: 270px;
  height: 100%;
  align-items: center;
  padding-right: $unit-s;
  padding-left: $unit-s;

  background-color: $color-brand-dark;

  @media all and (min-width: 1600px) {
    right: 100%;
    left: auto;
    width: 220px;
  }

  @media all and (min-width: 1700px) {
    width: 270px;
  }

  @include darkTheme() {
    background-color: $color-brand-dark--darken;
  }

  .desktop-navigation__search.toggle--active &,
  .desktop-navigation__search:target & {
    display: flex;
  }
}
