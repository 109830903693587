.checklist__item {
  margin: $unit-l 0;

  .enhanced & {
    display: grid;

    grid-template-columns: $unit-l auto;
    align-content: start;
  }

}

.checklist__progress {
  display: none;

  .enhanced & {
    display: block;

    width: 100%;
    appearance: none;
    background: transparent;

    border: 1px solid $color-border;
    @include darkTheme() {
      border-color: $color-border-light;
    }

    &::-webkit-progress-bar {
      background: transparent;
    }

    &::-webkit-progress-value {
      background: $color-brand-dark;
      @include darkTheme() {
        background: $color-brand-dark--darken;
      }
    }

    &::-moz-progress-bar {
      background: $color-brand-dark;
      @include darkTheme() {
        background: $color-brand-dark--darken;
      }
    }
  }
}

.form__checkbox-wrapper {
  display: none;

  .enhanced & {
    display: block;
  }
}

.checklist__item-content {
  padding: 0 $unit-m;
}
