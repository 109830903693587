$table-offset: 100px;

.table {
  clear: both;
  overflow: hidden;
}

.table--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(0 0 0 0);
  border: 0;

  &.toggle--active {
    position: relative;
    width: auto;
    height: auto;
    clip: auto;
  }
}

.table__wrapper {
  padding-top: $unit-l;
  border: 1px solid $color-border;

  @include darkTheme() {
    border: 1px solid $color-border-light;
  }
}

.table__inner {
  margin-bottom: -$table-offset;
  padding-bottom: $table-offset;

  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .enhanced & {
    cursor: grab;
  }

  .enhanced &:active {
    cursor: grabbing;
  }
}

.table table {
  min-width: 100%;

  border-collapse: collapse;
  text-align: right;
}

.table td,
.table th {
    padding: $unit-s;
}

.table thead {
  background-color: $color-border;

  @include darkTheme() {
    background-color: $color-border-light;
  }
}

.table thead th {
  width: 500px;
}

.table tbody tr {
  &:nth-child(even) {
    background-color: rgba($color-black, .05);
  }

  @include darkTheme() {
    &:nth-child(even) {
      background-color: rgba($color-light, .03);
    }
  }
}

.table caption {
  caption-side: bottom;
  text-align: right;
}
