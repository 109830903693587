@mixin fontBase {
  font-family: "farhangvariable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-feature-settings: "kern" on, "liga" on, "calt" on;
  -moz-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -ms-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -o-font-feature-settings: "kern" on, "liga" on, "calt" on;
  font-variation-settings: "wght" 400, "SHAR" 0, "SERI" 0, "KASH" 0;
}

@mixin fontAccent {
  font-family: "anjomanweb", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-feature-settings: "kern" on, "liga" on, "calt" on, "locl" on;
  -moz-font-feature-settings: "kern" on, "liga" on, "calt" on, "locl" on;
  -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on, "locl" on;
  -ms-font-feature-settings: "kern" on, "liga" on, "calt" on, "locl" on;
  -o-font-feature-settings: "kern" on, "liga" on, "calt" on, "locl" on;
  font-variation-settings: "wght" 300, "wdth" 75, "slnt" 0;
}

@mixin bold($font: "font-base") {
  @if $font == 'font-base' {
    @include fontBase();
    font-variation-settings: "wght" 650, "SHAR" 0, "SERI" 0, "KASH" 0;
  }

  @if $font == 'font-accent' {
    @include fontAccent();
    font-variation-settings: "wght" 550, "wdth" 75, "slnt" 0;
  }
}

@mixin font-smoothing($light-text: true) {
  @if $light-text == true {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  } @else {
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
  }
}

@mixin imageOverlay {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 50%;

  background-image: linear-gradient(to bottom, rgba(black, 0), rgba(black, 0.8));
  background-size: cover;
  pointer-events: none;
}

@mixin darkTheme {
  html.color-theme-dark & {
    @content;
  }

  @media (prefers-color-scheme: dark) {
    html:not(.color-theme-light) & {
      @content;
    }
  }
}

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}
